import { manualRetry } from "../../../services/webhookstatusService";
import { message } from "antd";

export function useRetryExtration() {
  const handleRetry = async (projectId, conversationId) => {
    try {
      const response = await manualRetry(projectId, conversationId);
      if (response.status === 200) {
        message.success(
          "Extraction complete. Your extraction status will be updated soon."
        );
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data.msg);
      }
    }
  };

  return { handleRetry };
}
