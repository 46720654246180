import React, { useState } from "react";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as projectService from "../../services/projectService";
import { message } from "antd";

import S3Icon from "v2/images/s3.svg";

function S3({ projectId, settings, fetchSettings }) {
  const [formFieldforZohoCRM] = useState({
    awsAccessKeyID: settings?.awsCredentials?.awsAccessKeyID,
    awsSecretAccessKey: settings?.awsCredentials?.awsSecretAccessKey,
    awsRegion: settings?.awsCredentials?.awsRegion,
    awsBucket: settings?.awsCredentials?.awsBucket,
  });

  const [loading, setLoading] = useState(false);

  const [errorMessageZohoCRM] = useState({});

  const templateZohoCRM = [
    {
      name: "awsAccessKeyID",
      label: "AWS Access key",
      type: "text",
      style: "",
    },
    {
      name: "awsSecretAccessKey",
      label: "AWS SecretKey",
      type: "password",
      style: "",
    },
    {
      name: "awsRegion",
      label: "AWS Region",
      type: "text",
      style: "",
    },

    {
      name: "awsBucket",
      label: "AWS Bucket",
      type: "text",
      style: "",
    },
  ];

  const buttonInfoZohoCRM = {
    label: "Connect S3",
    style: "entire-width",
  };

  const schemaZohoCRM = {
    awsAccessKeyID: Joi.string().max(5000).required().label("AWS Access key"),
    awsSecretAccessKey: Joi.string()
      .max(5000)
      .required()
      .label("AWS Secret key"),
    awsRegion: Joi.required().label("AWS Region"),
    awsBucket: Joi.string().max(5000).required().label("AWS bucket"),
  };

  const handleS3Connection = async (data) => {
    setLoading(true);
    try {
      const response = await projectService.updateProject(projectId, {
        awsCredentials: data,
      });
      if (response.status === 200) {
        message.success("Changes saved");
        setLoading(false);
        fetchSettings(projectId);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false); // Ensure setLoading is defined and used correctly
        message.error(ex.response.data.msg);
      } else {
        setLoading(false);
        console.error("An unexpected error occurred:", ex);
      }
    }
  };

  return (
    <div>
      <div className="p-8 rounded-lg border">
        <div className="flex gap-x-2 mb-4">
          <img src={S3Icon} width={24} />
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Connect S3
          </h3>
        </div>
        <Form
          errorMessage={errorMessageZohoCRM}
          inputField={formFieldforZohoCRM}
          template={templateZohoCRM}
          schema={schemaZohoCRM}
          buttonInfo={buttonInfoZohoCRM}
          onSubmit={handleS3Connection}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
    </div>
  );
}

export default S3;
