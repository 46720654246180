import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../common/table";
import { Button } from "../../../common/button";
import { XMarkIcon, PencilIcon, TrashIcon } from "@heroicons/react/16/solid";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../../../common/alert";
import * as customActionService from "../../../services/customActions";
import { message } from "antd";
import EditCallEnd from "./EditCallEnd";
import { Dialog, DialogBody, DialogTitle } from "../../../common/dialog";

function DeleteAlert({ isOpen, onClose, onDelete }) {
  return (
    <Alert open={isOpen} onClose={onClose} size="sm">
      <AlertTitle>Delete condition</AlertTitle>
      <AlertDescription>
        Once deleted, this information will be removed from our system, and no
        further actions will be taken regarding this condition.
      </AlertDescription>
      <AlertActions>
        <Button plain onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onDelete}>Delete</Button>
      </AlertActions>
    </Alert>
  );
}

function ExistingEndCallConditions({
  projectId,
  actions,
  refetchActions,
}) {
  const [dialogState, setDialogState] = useState({
    mode: "",
    isOpen: false,
    item: {},
  });

  const showEditDialog = (item) => {
    setDialogState({
      mode: "EDIT",
      isOpen: true,
      item,
    });
  };

  const showDeleteDialog = (item) => {
    setDialogState({
      mode: "DELETE",
      isOpen: true,
      item,
    });
  };

  const closeDialog = () => {
    setDialogState({
      mode: "",
      isOpen: false,
      item: {},
    });
  };

  const handleDeleteConfirmation = async () => {
    try {
      const response = await customActionService.deleteSMSAction(
        projectId,
        dialogState.item._id
      );

      if (response.status === 200) {
        message.success("Condition deleted");
        closeDialog();
        refetchActions();
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
    }
  };

  return (
    <>
      <div className="border rounded-lg p-4 max-h-[calc(100vh-150px)] min-h-[40vh] overflow-auto">
        <Table className="p-5">
          <TableHead>
            <TableRow>
              <TableHeader>Event name</TableHeader>
              <TableHeader>Action</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {actions &&
              actions.length > 0 &&
              actions.map((item, index) => (
                <TableRow key={index}>
                  <TableCell className="font-medium">
                    {item.actionName}
                  </TableCell>
                  <TableCell>
                    <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                      <div className="-mx-3 -my-1.5 sm:-mx-2.5 flex flex-row gap-x-2">
                        <Button outline onClick={() => showEditDialog(item)}>
                          <PencilIcon />
                        </Button>
                        <Button outline onClick={() => showDeleteDialog(item)}>
                          <TrashIcon />
                        </Button>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>

      {dialogState.mode === "DELETE" && (
        <DeleteAlert
          isOpen={dialogState.isOpen}
          onClose={closeDialog}
          onDelete={() => handleDeleteConfirmation()}
        />
      )}
      {dialogState.mode === "EDIT" && (
        <Dialog
          className=""
          open={dialogState.isOpen}
          onClose={closeDialog}
          size="2xl"
        >
          <DialogTitle>
            <div class="text-lg flex flex-row justify-between">
              <div>Edit Condition</div>
              <div class="text-lg">
                <Button plain onClick={closeDialog}>
                  <XMarkIcon />
                </Button>
              </div>
            </div>
          </DialogTitle>
          <DialogBody>
            <EditCallEnd
              projectId={projectId}
              item={dialogState.item}
              refetchActions={refetchActions}
              onCancel={closeDialog}
            />
          </DialogBody>
        </Dialog>
      )}
    </>
  );
}

export default ExistingEndCallConditions;
