import Joi from "joi-browser";

function validate(data, schema) {
  const options = { abortEarly: false };
  const { error } = Joi.validate(data, schema, options);

  if (!error) return null;

  const errors = {};

  error.details.forEach((item) => {
    errors[item.path[0]] = item.message;
  });

  return errors;
}

function validateProperty({ name, value }, schema) {
  const obj = { [name]: value };
  const schemaNew = { [name]: schema[name] };
  const { error } = Joi.validate(obj, schemaNew);
  return error ? error.details[0].message : null;
}

export { validate, validateProperty };
