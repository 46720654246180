import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import { message } from "antd";
import * as customActionService from "../../../services/customActions";


function EditCallEnd({ projectId, item, refetchActions, onCancel }) {

  const [inputField] = useState({
    actionName: item ? item.actionName : "",
    description: item ? item.description : "",
  });

  let [previewData, setPreviewData] = useState("");

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "actionName",
      label: "Name of the event",
      type: "text",
    },
    {
      name: "description",
      label: "Describe when do you want to end the call",
      type: "textArea",
      style: { height: "150px" },
    },
  ];

  const buttonInfo = { buttons: "2", primaryLabel: "Save", secondaryLabel: "Cancel" };

  const schema = {
    actionName: Joi.string().required().max(50).label("Event name"),
    description: Joi.string().max(1500).label("Description"),
  };

  const handleYes = async (data) => {

    data.actionFunction = "CALLEND"
    try {
      const response = await customActionService.updateCustomAction(
        projectId,
        item._id,
        data
      );

      if (response.status === 200) {
        setLoading(false);
        refetchActions();
        onCancel();
        message.success("Condition updated");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
      setLoading(false);
    }
  };

  const getPreviewDataInParent = (dataDuplicate) => {
    if (!dataDuplicate) {
      setPreviewData("");
    }
    setPreviewData(dataDuplicate);
  };

  return (
    <Form
      key={JSON.stringify(inputField)}
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={buttonInfo}
      onSubmit={handleYes}
      loading={loading}
      setLoading={setLoading}
      getValue={getPreviewDataInParent}
      handleSecondary={onCancel}
    />

  );
}

export default EditCallEnd;
