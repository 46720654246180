import React, { useState, useCallback } from "react";

import { getAllWebhooks } from "../../../services/webhookService";

import { TbWebhook } from "react-icons/tb";

import { CreateWebhookDialog } from "./Create";
import { SavedWebhooks } from "./SavedWebhooks";
import WebhooksRunHistory from "./RunHistory";

import TabsWithBadge from "../../../common/tabsWithBadge";

const TABS = {
  SAVED: "Saved",
  RUN_HISTORY: "Run History",
};

const Tabs = {
  [TABS.SAVED]: (props) => <SavedWebhooks {...props} />,
  [TABS.RUN_HISTORY]: (props) => <WebhooksRunHistory {...props} />,
};

// export function Webhooks({ projects, activeProject }) {
//   const [webhooks, setWebhooks] = useState([]);

//   const fetchWebhooks = useCallback(async (projectId) => {
//     try {
//       const response = await getAllWebhooks({ projectId });
//       const data = response.data;

//       setWebhooks(data.webhooks);
//     } catch (error) {
//       console.error("Error fetching saved webhooks", error);
//     }
//   }, []);

//   const [activeTab, setActiveTab] = useState(TABS.SAVED);

//   const tabs = Object.values(TABS).reduce((acc, tab) => {
//     return [...acc, { name: tab, href: "#", current: tab === activeTab }];
//   }, []);

//   return (
//     <main className="lg:pl-22 h-screen">
//       <div className="xl:pr-96">
//         <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
//           <div className=" overflow-visible rounded-lg bg-white shadow my-10">
//             <div className="px-4 pt-8 pb-2 sm:px-6 flex justify-between gap-x-4">
//               <div className="flex flex-col gap-y-2">
//                 <div className="flex grow-0 gap-x-2 items-center">
//                   <TbWebhook size={24} />
//                   <p className="font-medium text-xl">Webhooks</p>
//                 </div>
//                 <div></div>
//               </div>
//               <div className="flex-none">
//                 <CreateWebhookDialog
//                   projects={projects}
//                   activeProject={activeProject}
//                   onSubmit={() => {
//                     setActiveTab(TABS.SAVED);
//                     fetchWebhooks(projects[activeProject]._id);
//                   }}
//                 />
//               </div>
//             </div>
//             <TabsWithBadge
//               tabs={tabs}
//               onTabChange={(e, { tab }) => {
//                 setActiveTab(tab.name);
//               }}
//             />
//             {Tabs[activeTab]({
//               webhooks,
//               fetchWebhooks,
//               projects: projects,
//               activeProject: activeProject,
//             })}
//           </div>
//         </div>
//       </div>
//     </main>
//   );
// }

export function Webhooks({projectId}) {
  const [webhooks, setWebhooks] = useState([]);

  const fetchWebhooks = useCallback(async (projectId) => {
    try {
      const response = await getAllWebhooks({ projectId });
      const data = response.data;

      setWebhooks(data.webhooks);
    } catch (error) {
      console.error("Error fetching saved webhooks", error);
    }
  }, []);

  const [activeTab, setActiveTab] = useState(TABS.SAVED);

  const tabs = Object.values(TABS).reduce((acc, tab) => {
    return [...acc, { name: tab, href: "#", current: tab === activeTab }];
  }, []);

  return (
    <div className="w-full flex flex-col items-center justify-center p-8">
      <div className="w-full flex flex-col gap-y-2">
        <div className="flex justify-between mt-4">
          <p className="font-bold text-lg">Webhooks</p>
          <div className="flex-none">
            <CreateWebhookDialog
              projectId={projectId}
              onSubmit={() => {
                setActiveTab(TABS.SAVED);
                fetchWebhooks(projectId);
              }}
            />
          </div>
        </div>
        <TabsWithBadge
          className="border-b"
          tabs={tabs}
          onTabChange={(e, { tab }) => {
            setActiveTab(tab.name);
          }}
        />
        <div className="border rounded-lg mt-4 max-h-[75vh] overflow-scroll">
          {Tabs[activeTab]({
            webhooks,
            fetchWebhooks,
            projectId
          })}
        </div>
      </div>
    </div>
  );
}
