import { useState } from "react";

import { validate, validateProperty } from "./formValidation";

export function useForm(inputField, schema) {
  const [data, setData] = useState(inputField);
  const [error, setError] = useState({});

  const handleChange = ({ currentTarget: input, toggleValue, listValues }) => {
    let newData = { ...data };
    let newError = { ...error };

    // Determines the correct value to use based on the input type
    const targetValue =
      listValues !== undefined
        ? listValues
        : toggleValue !== undefined
        ? toggleValue
        : input.value;

    // Validates the specific property of the input based on schema
    const errorMessage = validateProperty(input, schema);

    // Updates error state if there's a validation error
    if (errorMessage) newError[input.name] = errorMessage;
    else delete newError[input.name];

    // Updates the data state with the new value
    newData[input.name] = targetValue;

    // Optional callback for real-time value updates
    // if (getValue) getValue(newData);

    // Set the new data and error state
    setData(newData);
    setError(newError);

    // Run overall validation if needed
    // if (setErrorState) validate();
  };

  return {
    data,
    error,
    validate,
    setError,
    handleChange,
  };
}

