import { getMediaFromConversation } from "v2/services/conversationService";

async function getMedia({ projectId, conversationId, mediaId }) {
  try {
    const response = await getMediaFromConversation({
      projectId,
      conversationId,
      mediaId,
    });

    const data = response.data;
    const type = data.type.split("/")[1];
    const url = URL.createObjectURL(data);

    return { url, type };
  } catch (error) {
    console.error(error);
  }
}

export { getMedia };
