import { useCallback, useState } from "react";
import { deleteConversation } from "../../../services/conversationService";
import { message } from "antd";

export function useDeleteConversation(fetchConversation, onClose) {
  const [loading, setLoading] = useState(false);

  const handleDeleteConversation = useCallback(
    async (projectId, conversationId) => {
      setLoading(true);

      try {
        const response = await deleteConversation(projectId, conversationId);

        if (response.status === 200) {
          setLoading(false);
          fetchConversation(projectId);
          onClose();
          message.success("Conversation deleted");
        }
      } catch (error) {
        setLoading(false);
        onClose();
        message.error("Failed to delete conversation");
      }
    }
  );

  return {
    loading,
    handleDeleteConversation,
  };
}
