export const CONVERSATION_SOURCE = {
  WHATSAPP: {
    id: "WHATSAPP",
    label: "Whatsapp",
  },
  SMS: { id: "SMS", label: "Sms" },
  EMBEDDEDCHAT: { id: "EMBEDDEDCHAT", label: "Embdedded Chat" },
  MESSENGER: { id: "MESSENGER", label: "Messenger" },
  INBOUNDVOICE: { id: "INBOUNDVOICE", label: "Inbound voice" },
  OUTBOUNDVOICE: { id: "OUTBOUNDVOICE", label: "Outbound voice" },
  WEBCALL: { id: "WEBCALL", label: "Web call" },
};
