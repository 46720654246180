import React, { useEffect, useState } from "react";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import CopyToClipboard from "react-copy-to-clipboard";
import CopyInput from "../common/CopyInput";
import { message } from "antd";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../common/alert";
import { Button } from "../common/button";
import { Input } from "../common/input";
import * as keyService from "../services/keyService";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../common/table";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../common/dropdown";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import { useOutletContext } from "react-router-dom";

function HomeAPISettings() {
  const { projectId } = useOutletContext();

  const [copyProjectId, setCopyProjectId] = useState("");
  let [isOpen, setIsOpen] = useState(false);

  const [key, setKey] = useState("");
  const [keyData, setKeys] = useState([]);
  const [reload, setReload] = useState(false);

  const fetchKeys = async (projectId) => {
    const { data } = await keyService.allKeys(projectId);
    setKeys(data);
  };

  const handleCopyButton = () => {
    message.success("Project ID copied");
  };

  useEffect(() => {
    if (projectId) {
      fetchKeys(projectId);
    }
  }, [isOpen, projectId]);

  const handleCreateKey = async () => {
    message.info("please wait");
    try {
      const response = await keyService.generateKey(projectId);
      if (response.status === 200) {
        setKey(response.data.apiKey.key);
        setIsOpen(true);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error("Please upgrade your account");
        setIsOpen(false);
        return;
      }
      message.error("Something is wrong");
      setIsOpen(false);
      return;
    }
  };

  const getTime = (data) => {
    if (data === 0) return "Never";

    const epochTime = data;
    const date = new Date(epochTime);
    const dateString = date.toLocaleString(); // or use toUTCString() or toDateString() as needed
    return dateString;
  };

  const handleDeleteKey = async (etag) => {
    setKey("");
    try {
      const { data } = await keyService.deleteKey(projectId, etag);
      message.success("Key deleted succesfully");
      fetchKeys(projectId);
    } catch (error) {
      message.error("Failed to delete key");
    }
  };

  return (
    <div>
      <main className="h-[calc(100dvh-20px)]">
        <div className="flex justify-center">
          <div className="w-3/4 m-16">
            <div className="flex flex-col gap-y-8">
              <div className="flex justify-between items-center">
                <h3 className="font-semibold text-xl">API Settings</h3>
              </div>
              <div className="w-1/4">
                <CopyInput value={projectId} title="Project ID" />
              </div>
              <div className="flex flex-col gap-y-2">
                <div>
                  <div className="flex justify-between items-center pb-2">
                    <h3 className="font-semibold text-md">API Keys</h3>
                    <Button type="button" onClick={handleCreateKey}>
                      Create Key
                    </Button>
                  </div>
                </div>
                <div className="border rounded-lg p-2 h-[250px] max-h-[calc(100vh-300px)] overflow-scroll">
                  <Table className="h-full">
                    <TableHead>
                      <TableRow>
                        <TableHeader>Secret Key</TableHeader>
                        <TableHeader>Created</TableHeader>
                        <TableHeader>Last Used</TableHeader>
                        <TableHeader className="relative w-0">
                          <span className="sr-only">Actions</span>
                        </TableHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {keyData &&
                        keyData.apiKeys &&
                        keyData.apiKeys.length >= 1 &&
                        keyData.apiKeys.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell className="font-medium">
                              {item.redactedKey}
                            </TableCell>
                            <TableCell>{getTime(item.createdAt)}</TableCell>
                            <TableCell className="text-zinc-500">
                              {getTime(item.lastUsed)}
                            </TableCell>
                            <TableCell>
                              <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                                <Dropdown>
                                  <DropdownButton
                                    plain
                                    aria-label="More options"
                                  >
                                    <EllipsisHorizontalIcon />
                                  </DropdownButton>
                                  <DropdownMenu anchor="bottom end">
                                    <DropdownItem
                                      onClick={() => handleDeleteKey(item._id)}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Alert open={isOpen} onClose={() => setIsOpen(false)} size="lg">
        <AlertTitle>API Key</AlertTitle>
        <AlertDescription>
          For security purposes, it's advised that you save this secret key in a
          secure and accessible location. Once saved, please note that it won't
          be visible again through your Superdash account. If the secret key is
          lost, generating a new one will be required.
        </AlertDescription>
        <AlertBody style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "100%",
            }}
          >
            <CopyInput key="key" value={key} title="Key"/>
          </div>
        </AlertBody>
        <AlertActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => setIsOpen(false)}>OK</Button>
        </AlertActions>
      </Alert>
    </div>
  );
}

export default HomeAPISettings;
