import React, { useState, useEffect } from "react";
import { Checkbox, CheckboxField, CheckboxGroup } from "../../common/checkbox";
import { Description, Label } from "../../common//fieldset";
import { Button } from "../../common/button";
import * as projectService from "../../services/projectService";
import { message } from "antd";
import { useOutletContext, useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

function PIIEncryptionComponent() {
  const { projectId, settings, fetchSettings } = useOutletContext();
  const navigate = useNavigate();
  const [PIIEncryption, setPIIEncryption] = useState(
    settings?.RAGSettings?.PIIEncryption
  );

  const handleSave = async () => {
    const data = {
      PIIEncryption: PIIEncryption,
    };
    const response = await projectService.updateProject(projectId, {
      RAGSettings: data,
    });
    if (response.status === 200) {
      message.success("Changes saved");
      fetchSettings(projectId);
    }
  };

  const handleZohoContactsSearch = () => {
    //   message.info("Please click save to persist this configuration")
    message.info("Please contact sales to enable this feature");
    setPIIEncryption(!PIIEncryption);
  };

  return (
    <main className="">
      <div className="">
        <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
          <div className="flex flex-col gap-y-2">
            <p className="font-bold text-lg">PII Encryption</p>
            <p className="font-medium text-sm text-gray-500">
              Encrypt Personally Identifiable Information(PII)
            </p>
          </div>
          <div className="w-full mx-auto divide-gray-200 overflow-visible rounded-lg border my-10">
            {/* <div className="px-4 py-5 sm:px-6">
              Encrpt Personally Identifiable Information(PII)
            </div> */}
            <div className="p-5">
              <CheckboxGroup>
                <CheckboxField>
                  <Checkbox
                    name="zoho_crm_search"
                    onChange={handleZohoContactsSearch}
                    value={PIIEncryption}
                    checked={PIIEncryption}
                  />
                  <Label>Enable PII Encryption</Label>
                  <Description>
                    When enabled, all PII data will be automatically replaced
                    with #### for enhanced security.
                  </Description>
                </CheckboxField>
              </CheckboxGroup>
            </div>
            <div className="px-4 py-4 sm:px-6 flex justify-end">
              <Button disabled="true" onClick={handleSave}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default PIIEncryptionComponent;
