import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "v2/components/Form";
import { message } from "antd";
import * as humanAgentService from "v2/services/humanAgentService";
import {
  AdjustmentsHorizontalIcon,
  XMarkIcon,
} from "@heroicons/react/16/solid";
import { Button } from "v2/common/button";
import { Dialog, DialogTitle, DialogBody } from "v2/common/dialog";

import { useShowOriginalCallerId } from "./ShowOriginalCallerId/useShowOriginalCallerId";
import { ShowOriginalCallerID } from "./ShowOriginalCallerId";

function ConfigureAgentTransfer({
  projectId,
  settings,
  humanAgents,
  refetchHumanAgents,
}) {
  const [open, setOpen] = useState(false);

  const { toggleOriginalCallerId, handleToggle, handleSave } =
    useShowOriginalCallerId(projectId);

  return (
    <>
      <Button outline onClick={() => setOpen(true)}>
        <AdjustmentsHorizontalIcon /> Configure
      </Button>
      <Dialog
        className="relative top-20"
        open={open}
        onClose={() => setOpen(false)}
        size="2xl"
      >
        <DialogTitle>
          <div class="text-lg flex flex-row justify-between">
            <div>Phone Agent Settings</div>
            <div class="text-lg">
              <Button plain onClick={() => setOpen(false)}>
                <XMarkIcon />
              </Button>
            </div>
          </div>
        </DialogTitle>
        <DialogBody>
          <div className="flex flex-col gap-y-4">
            <div className="border border-slate-50 p-4 rounded-md shadow">
              <ShowOriginalCallerID
                value={toggleOriginalCallerId}
                onToggle={handleToggle}
                onSave={handleSave}
              />
            </div>
            <div className="border border-slate-50  px-4 pb-4 rounded-md shadow">
              <TransferMessageForm
                projectId={projectId}
                settings={settings}
                humanagentsInfo={humanAgents}
                refetchHumanAgents={refetchHumanAgents}
                onCancel={() => setOpen(false)}
              />
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
}

function TransferMessageForm({
  projectId,
  humanagentsInfo,
  refetchHumanAgents,
}) {
  const [inputField, setInputField] = useState({
    transferMessage: "",
    officeHours: true,
  });

  useEffect(() => {
    setInputField({
      transferMessage: humanagentsInfo ? humanagentsInfo.transferMessage : "",
      officeHours: humanagentsInfo ? humanagentsInfo.officeHours : false,
    });
  }, [humanagentsInfo]);

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "officeHours",
      label: "Transfer only during office hours",
      description:
        "When enabled, calls will be made to the agent only during office hours",
      type: "toggle",
    },
    {
      name: "transferMessage",
      label: "Transfer Message",
      description: "What do you want AI to say while doing this request",
      type: "textArea",
      style: { height: "130px" },
    },
  ];

  const buttonInfo = {
    label: "Save",
    style: "right-side",
    props: { color: "zinc" },
  };

  const schema = {
    transferMessage: Joi.string().required().max(250).label("Transfer Message"),
    officeHours: Joi.boolean().label("Office hours"),
  };

  const handleYes = async (data) => {
    try {
      const response = await humanAgentService.updateParentAgent(
        projectId,
        data
      );

      if (response.status === 200) {
        setLoading(false);
        refetchHumanAgents();
        message.success("Transfer hours and message updated");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
      setLoading(false);
    }
  };

  return (
    <Form
      key={JSON.stringify(inputField)}
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={buttonInfo}
      onSubmit={handleYes}
      loading={loading}
      setLoading={setLoading}
    />
  );
}

export default ConfigureAgentTransfer;
