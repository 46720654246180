const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  }).format(date);
};

export function ConversationDetails(props) {
  const { conversation } = props;

  return (
    <div className="bg-white border rounded-lg max-w-sm mx-auto p-6 space-x-4 mt-6">
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-start">
          <span className="bg-white pr-2 text-sm text-gray-500">
            Call Details
          </span>
        </div>
      </div>
      <div className="mt-4 flex justify-between">
        <span className="text-gray-500">Call ID</span>
        <span className="text-xs text-gray-400">{conversation?.callId}</span>
      </div>
      <div className="mt-4 flex justify-between">
        <span className="text-gray-500">Call duration</span>
        <span className="text-gray-400">
          {conversation?.attributes?.callDuration}
        </span>
      </div>
      <div className="mt-4 flex justify-between">
        <span className="text-gray-500">Call from</span>
        <span className="text-gray-400">
          {conversation &&
            conversation.attributes &&
            conversation.attributes.phone}
        </span>
      </div>
      <div className="mt-4 flex justify-between">
        <span className="text-gray-500">Call type</span>
        <span className="text-gray-400">
          {conversation &&
          conversation.attributes &&
          conversation.attributes.type === "twilioCall"
            ? "Programmatic Call"
            : "WebCall"}
        </span>
      </div>
      <div className="mt-4 flex justify-between">
        <span className="text-gray-500">Conversation start time</span>
        <span className="text-gray-400">
          {conversation && conversation.createdAt
            ? formatDate(conversation.createdAt)
            : ""}
        </span>
      </div>
    </div>
  );
}
