import React, { useState, useEffect } from "react";
import { TbWebhook } from "react-icons/tb";
import { MdSettingsVoice } from "react-icons/md";
import zohoCRM from "../../images/zohoCRM.svg";
import gohighLevel from "../../images/gohighlevel.png";
import { act } from "react-dom/test-utils";
import voiceEmbedImage from "../../images/voiceembed.svg";
import chatwidget from "../../images/chatWidget.svg";
import message from "../../images/message.svg";
import endCall from "../../images/endCall.svg";
import calltransfer from "../../images/callTransfer.svg";
import extraction from "../../images/extraction.svg";
import customAPI from "../../images/customAPI.svg";
import elevanlabs from "../../images/11labs.svg";
import azure from "../../images/azure.svg";
// import ElevanLabs from "./HomeModule/ElevanLabs";
// import AzureVoice from "./HomeModule/AzureVoice";
import { useThirdPartySettings } from "HomePages/HomeModule/HumanAgenTransfer/ShowOriginalCallerId/useThirdParyData";
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useLocation,
} from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

import AcuitySchedulingLogo from "v2/images/acuity.png";

export function SectionComponent(props) {
  const { section } = props;
  const navigate = useNavigate();

  return (
    <div className="mt-8" key={section.title}>
      <div className="flex justify-start">
        <span className="pr-2 text-md text-gray-500 mb-3">{section.title}</span>
      </div>
      <div className="grid grid-cols-3 gap-4">
        {section.items.map((item) => (
          <MenuItem
            key={item.name}
            name={item.name}
            Icon={item.Icon}
            iconProps={item.iconProps}
            onClick={() => navigate(item.href)}
          />
        ))}
      </div>
    </div>
  );
}

export function SectionListComponent(props) {
  const { onSectionClick, sectionName, isActive, icon } = props;
  const navigate = useNavigate();

  return (
    <div
      onClick={onSectionClick}
      className={`w-full h-16 flex items-center justify-between px-4 hover:bg-gray-200 hover:cursor-pointer ${
        isActive ? "bg-gray-200" : ""
      }`}
    >
      <div className="flex items-center gap-x-2 px-2">
        {icon && icon}
        <p className={`${isActive ? "font-semibold" : "font-medium"} text-sm`}>
          {sectionName}
        </p>
      </div>
      {isActive && (
        <div className="flex justify-end">
          <ChevronRightIcon className="size-5 text-gray-400" />
        </div>
      )}
    </div>
  );
}

const MODULES = {
  WEB_HOOKS: "Webhooks",
  CUSTOM_API: "Custom API",
  EXTRACTION: "Extraction",
  HUMAN_AGENT_TRANSFER: "Human Agent Transfer",
  TRIGGER_SMS: "Trigger SMS",
  END_CALL: "End Call",
  ZOHO_CRM: "Zoho Contacts",
  GO_HIGH_LEVEL: "GHL Contacts & Calendar",
  ACUITY_SCHEDULING: "Acuity Scheduling",
  RECORDINGS: "Recordings",
  VOICE_EMBED: "Voice embed",
  CHAT_EMBED: "Chat embed",
  PII_ENCRYPTION: "PII Encryption (beta)",
  // S3: "S3",
  // PINECONE: "Pinecone",
  // CHROMADB: "ChromaDB",
};

const MODULE_URL = {
  webhooks: MODULES.WEB_HOOKS,
  "custom-api": MODULES.CUSTOM_API,
  extraction: MODULES.EXTRACTION,
  "agent-transfer": MODULES.HUMAN_AGENT_TRANSFER,
  triggers: MODULES.TRIGGER_SMS,
  "end-call": MODULES.END_CALL,
  "zoho-crm": MODULES.ZOHO_CRM,
  ghl: MODULES.GO_HIGH_LEVEL,
  "pii-encryption": MODULES.PII_ENCRYPTION,
  "voice-embed": MODULES.VOICE_EMBED,
  "chat-embed": MODULES.CHAT_EMBED,
  recording: MODULES.RECORDINGS,
  "acuity-scheduling": MODULES.ACUITY_SCHEDULING,
};

// Generic icon component for images
const ImageIcon = ({ src, alt, width }) => (
  <img src={src} alt={alt} style={{ width: `${width}px` }} />
);

// const sections = [
//   // {
//   //   title: "Recordings",
//   {
//     title: "Voice calls & messaging",
//     items: [
//       { name: MODULES.WEB_HOOKS, Icon: TbWebhook, href: "/modules/webhooks" },
//     ],
//   },

//   // },

//   {
//     title: "Voice calls",
//     items: [
//       {
//         name: MODULES.CUSTOM_API,
//         iconProps: { src: customAPI, alt: "Extraction", width: 20 }, //TODO
//         href: "/modules/custom-api",
//       },
//       {
//         name: MODULES.EXTRACTION,
//         iconProps: { src: extraction, alt: "Extraction", width: 20 },
//         href: "/modules/extraction",
//       },
//       {
//         name: MODULES.HUMAN_AGENT_TRANSFER,
//         iconProps: {
//           src: calltransfer,
//           alt: "Human agent transfer",
//           width: 20,
//         },
//         href: "/modules/agent-transfer",
//       },
//       {
//         name: MODULES.TRIGGER_SMS,
//         iconProps: { src: message, alt: "Zoho CRM", width: 20 },
//         href: "/modules/triggers",
//       },

//       {
//         name: MODULES.END_CALL,
//         iconProps: { src: endCall, alt: "End Call", width: 20 },
//         href: "/modules/end-call",
//       },
//       {
//         name: MODULES.ZOHO_CRM,
//         iconProps: { src: zohoCRM, alt: "Zoho CRM", width: 50 },
//       },
//       {
//         name: MODULES.GO_HIGH_LEVEL,
//         iconProps: { src: gohighLevel, alt: "Go High Level", width: 20 },
//       },
//     ],
//   },

//   {
//     title: "Voice modules",
//     items: [{ name: MODULES.RECORDINGS, Icon: MdSettingsVoice }],
//   },

//   {
//     title: "Embed settings",
//     items: [
//       {
//         name: MODULES.VOICE_EMBED,
//         iconProps: { src: voiceEmbedImage, alt: "VoiceEmbed", width: 20 },
//       },
//       {
//         name: MODULES.CHAT_EMBED,
//         iconProps: { src: chatwidget, alt: "chatEmbed", width: 20 },
//       },
//     ],
//   },

//   {
//     title: "Encryption",
//     items: [{ name: MODULES.PII_ENCRYPTION, Icon: TbWebhook }],
//   },

//   {
//     title: "Databse",
//     items: [{ name: MODULES.S3, iconProps: { src: S3, alt: "S3", width: 20 } }],
//   },

//   {
//     title: "Vector Database",
//     items: [
//       {
//         name: MODULES.PINECONE,
//         iconProps: { src: pinecone, alt: "Pinecone", width: 20 },
//       },
//       {
//         name: MODULES.CHROMADB,
//         iconProps: { src: Chroma, alt: "ChromaDB", width: 80 },
//       },
//     ],
//   },
// ];

const sections = [
  { name: MODULES.WEB_HOOKS, Icon: TbWebhook, href: "/modules/webhooks" },
  {
    name: MODULES.CUSTOM_API,
    iconProps: { src: customAPI, alt: "Extraction", width: 20 }, //TODO
    href: "/modules/custom-api",
  },
  {
    name: MODULES.EXTRACTION,
    iconProps: { src: extraction, alt: "Extraction", width: 20 },
    href: "/modules/extraction",
  },
  {
    name: MODULES.HUMAN_AGENT_TRANSFER,
    iconProps: {
      src: calltransfer,
      alt: "Human agent transfer",
      width: 20,
    },
    href: "/modules/agent-transfer",
  },
  {
    name: MODULES.TRIGGER_SMS,
    iconProps: { src: message, alt: "Zoho CRM", width: 20 },
    href: "/modules/triggers",
  },

  {
    name: MODULES.END_CALL,
    iconProps: { src: endCall, alt: "End Call", width: 20 },
    href: "/modules/end-call",
  },
  {
    name: MODULES.ZOHO_CRM,
    iconProps: { src: zohoCRM, alt: "Zoho CRM", width: 20 },
    href: "/modules/zoho-crm",
  },
  {
    name: MODULES.GO_HIGH_LEVEL,
    iconProps: { src: gohighLevel, alt: "Go High Level", width: 20 },
    href: "/modules/ghl",
  },
  {
    name: MODULES.ACUITY_SCHEDULING,
    iconProps: {
      src: AcuitySchedulingLogo,
      alt: "Acuity Schdeuling",
      width: 20,
    },
    href: "/modules/acuity-scheduling",
  },
  {
    name: MODULES.PII_ENCRYPTION,
    Icon: TbWebhook,
    href: "/modules/pii-encryption",
  },
  {
    name: MODULES.RECORDINGS,
    Icon: MdSettingsVoice,
    href: "/modules/recording",
  },

  {
    name: MODULES.VOICE_EMBED,
    iconProps: { src: voiceEmbedImage, alt: "VoiceEmbed", width: 20 },
    href: "/modules/voice-embed",
  },
  {
    name: MODULES.CHAT_EMBED,
    iconProps: { src: chatwidget, alt: "chatEmbed", width: 20 },
    href: "/modules/chat-embed",
  },
];

function MenuItem({ name, Icon, iconProps, onClick }) {
  return (
    <div
      className={`flex flex-col gap-y-4 h-32 border border-gray-200 p-3 rounded-xl cursor-pointer mb-2 hover:bg-slate-100`}
      onClick={onClick}
    >
      <div className="flex items-center gap-x-2">
        {Icon ? <Icon /> : <ImageIcon {...iconProps} />}
        <span className="font-normal text-medium">{name}</span>
      </div>
      <div>
        <p className=" text-sm text-gray-400">
          Some description of what the module is about
        </p>
      </div>
    </div>
  );
}

function Modules() {
  const { projects, activeProject, settings, fetchSettings, projectId } =
    useOutletContext();
  const { fetchThirdPartySettings, updateThirdPartySettings } =
    useThirdPartySettings(projectId);
  const [thirdPartySettings, setThirdPartySettings] = useState(null);

  const [activeItem, setActiveItem] = useState("Webhooks");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      const module = location.pathname.split("/")[2];

      if (module) {
        setActiveItem(MODULE_URL[module]);
      } else {
        navigate("/modules/webhooks", { replace: true });
      }
    }
  }, []);

  useEffect(() => {
    if (projectId) {
      fetchThirdPartySettings(
        (data) => setThirdPartySettings(data),
        console.error
      );
    }
  }, [projectId]);

  const handleItemClick = (href) => {
    navigate(href);
  };

  const tabs = sections.map((module) => ({
    name: module.name,
    current: module.name === activeItem,
    href: module.href,
    icon: module.iconProps ? (
      <img src={module?.iconProps.src} width={module.iconProps.width} />
    ) : (
      <module.Icon />
    ),
  }));

  const refetchSettings = () => {
    fetchSettings(projectId);
  };

  return (
    <main className="w-full h-full flex">
      <div className="w-1/4 border-r h-full">
        <div className=" py-4 px-5 border-b">
          <p className="font-bold text-lg">Modules</p>
        </div>
        <div className="h-[calc(100vh-80px)] overflow-auto">
          {tabs.map((item) => (
            <SectionListComponent
              onSectionClick={() => {
                setActiveItem(item.name);
                navigate(item.href);
              }}
              isActive={item.current}
              sectionName={item.name}
              icon={item.icon}
            />
          ))}
        </div>
      </div>
      <div className="w-3/4 h-full overflow-auto">
        <Outlet
          context={{
            projects,
            activeProject,
            settings,
            fetchSettings,
            refetchSettings: fetchSettings,
            projectId,
            thirdPartySettings,
            updateThirdPartySettings: (updatedSettings, successMesssage) => {
              updateThirdPartySettings(
                updatedSettings,
                () => {
                  fetchThirdPartySettings(
                    (data) => setThirdPartySettings(data),
                    console.error
                  );
                  successMesssage();
                },
                console.error
              );
            },
            thirdPartySettings,
          }}
        />
      </div>
    </main>
  );
}

export default Modules;
