import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../common/table";
import { Button } from "../../../common/button";
import { XMarkIcon, PencilIcon, TrashIcon } from "@heroicons/react/16/solid";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../../../common/alert";
import * as projectService from "../../../services/projectService";
import { message } from "antd";
import EditExtraction from "./EditExtraction";
import { Dialog, DialogBody, DialogTitle } from "../../../common/dialog";

function DeleteExtraction({ isOpen, onClose, onDelete, loading }) {
  return (
    <Alert open={isOpen} onClose={onClose} size="sm">
      <AlertTitle>Delete Field</AlertTitle>
      <AlertDescription>
        Once deleted, this field will no longer be extracted from the
        conversation
      </AlertDescription>
      <AlertBody style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        ></div>
      </AlertBody>
      <AlertActions>
        <Button plain onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onDelete} disabled={loading}>
          Delete
        </Button>
      </AlertActions>
    </Alert>
  );
}

function EditExtractionDialog({
  projectId,
  extraction,
  item,
  index,
  fetchExtractions,
  isOpen,
  onClose,
}) {
  return (
    <Dialog
      className="relative top-20"
      open={isOpen}
      onClose={onClose}
      size="2xl"
    >
      <DialogTitle>
        <div class="text-lg flex flex-row justify-between">
          <div>Edit extraction field</div>
          <div class="text-lg">
            <Button plain onClick={onClose}>
              <XMarkIcon />
            </Button>
          </div>
        </div>
      </DialogTitle>
      <DialogBody>
        <EditExtraction
          projectId={projectId}
          extraction={extraction}
          item={item}
          index={index}
          fetchExtractions={fetchExtractions}
          handleSecondary={onClose}
        />
      </DialogBody>
    </Dialog>
  );
}

function ExtractionFields({ projectId, extraction, fetchExtractions }) {
  const [loading, setLoading] = useState(false);

  const [action, setAction] = useState({
    mode: null,
    item: null,
    index: null,
  });

  const MAX_LENGTH = 50;

  // Function to truncate the text
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  const editItem = (item, index) => {
    setAction({
      mode: "EDIT",
      item,
      index,
    });
  };

  const deleteItem = (item, index) => {
    setAction({
      mode: "DELETE",
      item,
      index,
    });
  };

  const resetAction = () => {
    setAction({
      mode: null,
      item: null,
      index: null,
    });
  };

  const handleDeleteConfirmation = async (req) => {
    message.success("Please wait. We're sending the request.");

    setLoading(true);

    const newArray = extraction.extraction.extractionParameters;
    const modifiedArray = newArray.filter((_, i) => i !== action.index);

    let data = {};
    data.extractionParameters = [...modifiedArray];

    try {
      const response = await projectService.createExtraction(projectId, data);

      if (response.status === 200) {
        message.success("Field deleted");
        fetchExtractions(projectId);
        resetAction();
        setLoading(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchExtractions(projectId);
    }
  }, [projectId]);

  return (
    <>
      <Table className="p-5 border rounded-lg">
        <TableHead>
          <TableRow>
            <TableHeader>Field name</TableHeader>
            <TableHeader>Type</TableHeader>
            <TableHeader>Instruction</TableHeader>
            <TableHeader>Action</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {extraction &&
            extraction.extraction &&
            extraction.extraction.extractionParameters.map((item, index) => (
              <TableRow key={index}>
                <TableCell className="font-medium">
                  {item.parameterName}
                </TableCell>
                <TableCell>{item.parameterType}</TableCell>
                <TableCell key={index}>
                  {truncateText(item.parameterDescription, MAX_LENGTH)}
                </TableCell>
                <TableCell>
                  <div className="-mx-3 -my-1.5 sm:-mx-2.5 flex gap-x-2">
                    <Button outline onClick={() => editItem(item, index)}>
                      <PencilIcon />
                    </Button>
                    <Button outline onClick={() => deleteItem(item, index)}>
                      <TrashIcon />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      {action.mode === "DELETE" && (
        <DeleteExtraction
          isOpen={true}
          onClose={resetAction}
          onDelete={handleDeleteConfirmation}
          loading={loading}
        />
      )}
      {action.mode === "EDIT" && (
        <EditExtractionDialog
          isOpen={true}
          onClose={resetAction}
          projectId={projectId}
          item={action.item}
          index={action.index}
          extraction={extraction}
          fetchExtractions={fetchExtractions}
        />
      )}
    </>
  );
}

export default ExtractionFields;
