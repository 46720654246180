import React, { useState, useEffect } from "react";
import InputComponent from "./InputComponent.jsx";
import Joi from "joi-browser";
import { Button } from "../common/button.jsx";

function Form({
  inputField,
  errorMessage,
  template,
  schema,
  buttonInfo,
  onSubmit,
  getValue,
  setErrorState,
  setLoading,
  loading,
  emptyState,
  setEmptyState,
  formTitle,
  handleSecondary,
  showRevert,
  enableActionsOnChange,
  options,
  step,
}) {
  const [data, setData] = useState(inputField);
  const [error, setError] = useState({ errorMessage });
  const [schemaCheck] = useState(schema);
  const [enableShow, setEnableShow] = useState(!enableActionsOnChange);

  useEffect(() => {
    if (emptyState === true) {
      setData(inputField);
      setError({ errorMessage });
      setEmptyState(false);
    }
  }, [emptyState]);

  useEffect(() => {
    setData(inputField);
  }, [inputField]);

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schemaCheck, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaNew = { [name]: schemaCheck[name] };
    const { error } = Joi.validate(obj, schemaNew);
    return error ? error.details[0].message : null;
  };

  const handleChange = ({ currentTarget: input, toggleValue, listValues }) => {
    setEnableShow(true);
    let newData = { ...data };
    let newError = { ...error };

    // Determines the correct value to use based on the input type
    const targetValue =
      listValues !== undefined
        ? listValues
        : toggleValue !== undefined
        ? toggleValue
        : input.value;

    // Validates the specific property of the input based on schema
    const errorMessage = validateProperty(input);

    // Updates error state if there's a validation error
    if (errorMessage) newError[input.name] = errorMessage;
    else delete newError[input.name];

    // Updates the data state with the new value
    newData[input.name] = targetValue;

    // Optional callback for real-time value updates
    if (getValue) getValue(newData);

    // Set the new data and error state
    setData(newData);
    setError(newError);

    // Run overall validation if needed
    if (setErrorState) validate();
  };

  const onchangeInput = (val, index) => {
    let newData = { ...data };
    newData.options[index] = val.target.value;
    if (getValue) getValue(newData);
    setData(newData);
  };

  const getButtonClasses = (style) => {
    let classes = style === "normal" ? "btnn " : "btnn btnn-strech ";
    return (classes += validate() ? "btnn-disable " : "btnn-primary");
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const errors = validate();
    setError(errors || {});
    if (errors) return;
    onSubmit(data);
  };

  const isPrimaryDisabled = validate();

  // console.log(validate() )
  return (
    <form className="w-full space-y-8">
      <h3 className="text-lg font-semibold tracking-[-0.015em] text-zinc-950 sm:text-base dark:text-white">
        {formTitle}
      </h3>

      {template.map((item, index) => (
        <InputComponent
          key={index}
          name={item.name}
          value={data[item.name]}
          label={item.label}
          type={item.type}
          placeholder={item.placeholder}
          onChange={handleChange}
          error={error[item.name]}
          style={item.style}
          options={item.options}
          arrayLength={item.arrayLength}
          onchangeInput={onchangeInput}
          description={item.description}
          min={item.min}
          max={item.max}
          defaultValue={item.defaultValue}
          step={item.step}
        />
      ))}

      {buttonInfo &&
        // enableShow &&
        (buttonInfo.buttons === "2" ? (
          <div className="flex justify-between w-full space-x-2">
            <Button
              onClick={handleSecondary}
              outline
              className="w-1/2 inline-flex items-center justify-center rounded-lg text-base font-semibold px-4 py-2 text-white hover:bg-gray-900 focus:outline-none"
            >
              {buttonInfo.secondaryLabel}
            </Button>
            <Button
              loading={loading}
              onClick={handleSubmit}
              disabled={isPrimaryDisabled || loading}
              className="w-1/2 inline-flex items-center justify-center rounded-lg border border-gray-300 text-base font-semibold px-4 py-2 text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none"
            >
              {buttonInfo.primaryLabel}
            </Button>
          </div>
        ) : buttonInfo.style === "entire-width" ? (
          <Button
            loading={loading}
            onClick={handleSubmit}
            disabled={validate() || loading}
            className="w-full inline-flex items-center justify-center rounded-lg border text-base font-semibold px-4 py-2 text-white bg-zinc-900 focus:outline-none"
          >
            {buttonInfo.label}
          </Button>
        ) : (
          buttonInfo.style === "right-side" && enableShow && (
            <div className="flex justify-end gap-x-4">
              <Button
                {...buttonInfo?.props}
                onClick={(data) => {
                  handleSubmit(data);
                  enableActionsOnChange && setEnableShow(false);
                }}
                disabled={validate() || loading}
              >
                {buttonInfo.label}
              </Button>
              {showRevert && (
                <Button
                  outline
                  onClick={() => {
                    setData(inputField);
                    setEnableShow(false);
                  }}
                >
                  Revert
                </Button>
              )}
            </div>
          )
        ))}
    </form>
  );
}

export default Form;
