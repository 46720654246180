import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../common/table";
import { Button } from "../../../common/button";
import {
  PencilIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/16/solid";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../../../common/alert";
import * as customAgentService from "../../../services/customActions";
import { message } from "antd";
import { Dialog, DialogBody, DialogTitle } from "../../../common/dialog";
import AddNewTrigger from "./AddNewTrigger";
import EnableCustomAction from "./EnableCustomAction";
import EditSMSTrigger from "./EditSMSTrigger";
import TriggerSMS from "../../../images/message.svg";

function AddNewTriggerCTA({ projects, activeProject, reload, setReload }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button outline onClick={() => setOpen(true)}>
        <PlusIcon />
        Add SMS Trigger
      </Button>
      <Dialog
        className=""
        size="2xl"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          <div class="text-lg flex flex-row justify-between">
            <div>Add SMS Trigger</div>
            <div class="text-lg">
              <Button plain onClick={() => setOpen(false)}>
                <XMarkIcon />
              </Button>
            </div>
          </div>
        </DialogTitle>
        <DialogBody className="text-sm/6">
          <AddNewTrigger
            projects={projects}
            activeProject={activeProject}
            onCancel={() => setOpen(false)}
            reload={reload}
            setReload={setReload}
          />
        </DialogBody>
      </Dialog>
    </>
  );
}

function ExistingSMSTriggers({
  projects,
  activeProject,
  allSMSTriggers,
  setItem,
  setFormState,
  item,
  setReload,
  reload,
  settings,
  refetchSettings,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const handleEdit = (item) => {
    // setFormState("edit");
    setItem(item);
  };

  const showDeleteModal = (item) => {
    setIsOpen(true);
    setItem(item);
  };

  const showEditModal = (item) => {
    setIsEditOpen(true);
    setItem(item);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const response = await customAgentService.deleteSMSAction(
        projects[activeProject]._id,
        item._id
      );

      if (response.status === 200) {
        message.success("Trigger deleted");
        setIsOpen(false);
        setReload(!reload);
        // setFormState("view");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
    }
  };

  return (
    <div className="h-full w-full flex justify-center p-8">
      <div className="w-full flex flex-col gap-y-8">
        <div className="flex justify-between pb-4">
          <div className="flex gap-x-2 items-end">
            {/* <img src={TriggerSMS} alt="trigger sms" width={25} /> */}
            <p className="font-semibold text-lg">SMS Triggers</p>
            <div className="self-end">
              <EnableCustomAction
                projects={projects}
                activeProject={activeProject}
                settings={settings}
                refetchSettings={refetchSettings}
              />
            </div>
          </div>

          <AddNewTriggerCTA
            projects={projects}
            activeProject={activeProject}
            reload={reload}
            setReload={setReload}
          />
        </div>
        <div>
          <div className="border p-2 rounded-lg max-h-[calc(100vh-150px)] h-[40dvh] overflow-auto">
            <Table className="">
              <TableHead>
                <TableRow>
                  <TableHeader>Event name</TableHeader>
                  <TableHeader>Phone Number</TableHeader>
                  <TableHeader>Action</TableHeader>
                </TableRow>
              </TableHead>

              <TableBody>
                {allSMSTriggers &&
                  allSMSTriggers.length > 0 &&
                  allSMSTriggers.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="font-medium">
                        {item.actionName}
                      </TableCell>
                      <TableCell>
                        {item.phoneNumber === ""
                          ? "Identified from call"
                          : item.phoneNumber}
                      </TableCell>

                      <TableCell>
                        <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                          <div className="-mx-3 -my-1.5 sm:-mx-2.5 flex flex-row gap-x-2">
                            <Button outline onClick={() => showEditModal(item)}>
                              <PencilIcon />
                            </Button>
                            <Button
                              outline
                              onClick={() => showDeleteModal(item)}
                            >
                              <TrashIcon />
                            </Button>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>

          <Dialog
            className=""
            open={isEditOpen}
            onClose={() => setIsEditOpen(false)}
            size="2xl"
          >
            <DialogTitle>
              <div class="text-lg flex flex-row justify-between">
                <div>Edit Trigger SMS</div>
                <div class="text-lg">
                  <Button plain onClick={() => setIsEditOpen(false)}>
                    <XMarkIcon />
                  </Button>
                </div>
              </div>
            </DialogTitle>
            <DialogBody>
              <EditSMSTrigger
                item={item}
                reload={reload}
                projects={projects}
                activeProject={activeProject}
                setReload={setReload}
                setFormState={setFormState}
                onCancel={() => setIsEditOpen(false)}
              />
            </DialogBody>
          </Dialog>
          <Alert open={isOpen} onClose={() => setIsOpen(false)} size="sm">
            <AlertTitle>Delete SMS Trigger</AlertTitle>
            <AlertDescription>
              Once deleted, this information will be removed from our system,
              and no further actions will be taken regarding this condition.
            </AlertDescription>
            <AlertActions>
              <Button outline onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button onClick={() => handleDeleteConfirmation()}>Delete</Button>
            </AlertActions>
          </Alert>
        </div>
      </div>
    </div>
  );
}

export default ExistingSMSTriggers;
