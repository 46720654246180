import http from "./httpService";
const apiEndpoint = "/customers";


export function signUpPortal(req) {
  return http.post(apiEndpoint + "/" + "signup", req);
}

export function addCustomer(projectId, req) {
    return http.post(apiEndpoint + "/" + projectId, req);
  }

  export function allCustomers(projectId) {
    return http.get(apiEndpoint + "/" + projectId);
  }

  export function deleteCustomer(companyId, projectId, Id) {
    return http.delete(apiEndpoint + "/" + companyId + "/" + projectId + "/" + Id);
  }


  export function updateCustomer(projectId,customerId, req) {
    return http.put(apiEndpoint + "/" + projectId + "/" + customerId, req);
  }


  export function findPortalCustomer() {
    return http.get(apiEndpoint + "/" + "customerdetails");
  }

  export function updateCustomerUsingSessionID(sessionId, req ) {
    return http.put(apiEndpoint + "/getaccess" + "/" + sessionId, req);
  }

  



