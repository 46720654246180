import {
  Conversation,
  ConversationItem,
  ConversationItemLoading,
} from "../conversation_item";

export function Conversations(props) {
  const {
    conversations,
    isSelectedConversation,
    updateSelectedConversation,
    showLoadMoreConversation,
    loadMoreConversations,
    loading,
  } = props;

  if (loading) {
    return (
      <>
        {[...Array(20).keys()].map((elem, idx) => (
          <ConversationItemLoading key={idx}/>
        ))}
      </>
    );
  }

  return (
    <>
      {conversations &&
        conversations.map((item, index) => {
          const { sourceOfConversation } = item;

          return Conversation[sourceOfConversation]({
            key: item._id,
            item,
            isSelected: isSelectedConversation(item._id),

            updateSelectedConversation: () => updateSelectedConversation(item),
          });
        })}
      {showLoadMoreConversation && (
        <div
          className="flex m-4 justify-center cursor-pointer"
          onClick={loadMoreConversations}
        >
          <p className="text-blue-600">Load more conversations</p>
        </div>
      )}
    </>
  );
}
