import { useCallback } from "react";

import {
  getthirdpartyData,
  thirdpartyUpdate,
} from "../../../../services/thirdpartyService";

export function useThirdPartySettings(projectId) {
  const fetchThirdPartySettings = useCallback(
    async (onSuccess, onError) => {
      try {
        const response = await getthirdpartyData(projectId);

        if (response.status === 200) {
          onSuccess(response.data);
        }
      } catch (error) {
        onError(error);
      }
    },
    [projectId]
  );

  const updateThirdPartySettings = useCallback(
    async (settings, onSuccess, onError) => {
      try {
        const response = await thirdpartyUpdate(settings, projectId);
        if (response.status === 200) {
          onSuccess(response);
        }
      } catch (error) {
        onError(error);
      }
    },
    [projectId]
  );

  return {
    fetchThirdPartySettings,
    updateThirdPartySettings,
  };
}
