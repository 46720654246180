import React, { useState } from "react";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as projectService from "../../services/projectService";
import { message } from "antd";

import ChromaDBIcon from "v2/images/chroma.svg";

function ChromaComponent({ projectId, settings, fetchSettings }) {
  const [chromaFields] = useState({
    chromaURL: settings?.vectorDbSettings?.chromaDbSettings.chromaURL,
    chromaPort: settings?.vectorDbSettings?.chromaDbSettings.chromaPort,
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const templateZohoCRM = [
    {
      name: "chromaURL",
      label: "Chroma URL",
      type: "text",
      style: "",
    },
    {
      name: "chromaPort",
      label: "Chroma Port",
      type: "text",
      style: "",
    },
  ];

  const buttonInfo = {
    label: "Connect ChromaDB",
    style: "entire-width",
  };

  const schemaforChroma = {
    chromaURL: Joi.string().max(5000).required().label("Chroma URL"),
    chromaPort: Joi.string().max(5000).required().label("Chroma Port"),
  };

  const handleChromaConnection = async (data) => {
    setLoading(true);

    let newData = {
      chromaDbSettings: {
        chromaURL: data.chromaURL,
        chromaPort: data.chromaPort,
      },
    };

    try {
      const response = await projectService.updateProject(projectId, {
        vectorDbSettings: newData,
      });
      if (response.status === 200) {
        message.success("Changes saved");
        setLoading(false);
        fetchSettings(projectId);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false); // Ensure setLoading is defined and used correctly
        message.error(ex.response.data.msg);
      } else {
        setLoading(false);
        console.error("An unexpected error occurred:", ex);
      }
    }
  };

  return (
    <div>
      <div className="p-8 rounded-lg border">
        <div className="flex gap-x-2 mb-4">
          <img src={ChromaDBIcon} width={60} />
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Connect Chroma DB
          </h3>
        </div>

        <Form
          errorMessage={errorMessage}
          inputField={chromaFields}
          template={templateZohoCRM}
          schema={schemaforChroma}
          buttonInfo={buttonInfo}
          onSubmit={handleChromaConnection}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
    </div>
  );
}

export default ChromaComponent;
