import Joi from "joi-browser";

const paramSchema = {
  paramName: Joi.string().required().max(30).label("Param Name"),
  persistParam: Joi.boolean().required().label("Persist Param"),
};

const inputParamSchema = {
  ...paramSchema,
  paramValue: Joi.string().allow("").max(30).label("Param Value"),
  paramType: Joi.string().required().label("Param Type"),
  paramLocation: Joi.string().required().label("Param Location"),
  paramPrefix: Joi.string().allow("").label("Param Prefix"),
};

const returnParamSchema = {
  ...paramSchema,
  useLocation: Joi.string().required().label("Use Location"),
  returnType: Joi.string().required().label("Return Type"),
};

export { inputParamSchema, returnParamSchema };
