import React, { useState, useEffect } from "react";
import * as customActionService from "../../../services/customActions";
import ExistingSMSTriggers from "./ExistingSMSTriggers";

function Configuration({ projects, activeProject,setTabState, settings, refetchSettings }) {
  const [allSMSTriggers, setAllSMSTriggers] = useState([]);

  // const [formState, setFormState] = useState("view");
  const [item, setItem] = useState({});
  const [reload, setReload] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await customActionService.getAllCustomActions(
          projects[activeProject]._id
        );
        const smsTriggers = response.data.customActions.filter(action => action.actionFunction === "SMS");

        setAllSMSTriggers(smsTriggers);  
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [projects, activeProject, reload]); // Adding dependencies


    return (
        <ExistingSMSTriggers
          projects={projects}
          settings={settings}
          activeProject={activeProject}
          allSMSTriggers={allSMSTriggers}
          setItem={setItem}
          setFormState={() => {}}
          item={item}
          setReload={setReload}
          reload={reload}
          refetchSettings={refetchSettings}
        />
    );
}

export default Configuration;
