import ZohoCRM from "v2/HomePages/HomeIntegrations/ZohoCRM";
import { IntegrationShell } from "../common/Shell";
import { useOutletContext } from "react-router-dom";

export function ZohoCRMIntegration() {
    const { projectId} = useOutletContext();

    return <IntegrationShell>
        <ZohoCRM projectId={projectId}/>
    </IntegrationShell>
}