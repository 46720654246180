import { message } from "antd";
import Joi from "joi-browser";
import { useEffect, useState } from "react";
import Form from "v2/components/Form";
import { updateProject } from "v2/services/projectService";

const interruptionSettingsFormModel = {
  vadInterruptionEnabled: {
    template: {
      label: "Detect Speech Activity to Interrupt Agent",
      description:
        "Enable this to pause voice agent response, as soon as the caller speaks. If not enabled, the agent will be interrupted only once the caller speaks more than 2 words",
      type: "toggle",
    },
    schema: Joi.boolean(),
  },
  speechThreshold: {
    template: {
      label: "Voice Sensitivity",
      type: "slider",
      min: 0.4,
      max: 0.9,
      step: 0.1,
    },
    schema: Joi.number(),
  },
};

const fields = Object.keys(interruptionSettingsFormModel).map((field) => {
  const template = interruptionSettingsFormModel[field].template;
  return {
    ...template,
    name: field,
  };
});

const schema = Object.keys(interruptionSettingsFormModel).reduce(
  (acc, field) => ({
    ...acc,
    [field]: interruptionSettingsFormModel[field].schema,
  }),
  {}
);

export function InterruptionSettings(props) {
  const { settings, projectId, fetchSettings, onClose } = props;

  const [interruptionSettings, setInterruptionSettings] = useState({
    vadInterruptionEnabled: false,
    speechThreshold: 0,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { _id, ...restInterruptionSettings } =
      settings?.voiceChatSettings.interruptionSettings;
    setInterruptionSettings(restInterruptionSettings);
  }, [settings]);

  const handleSave = async (data) => {
    const payload = {
      voiceChatSettings: {
        interruptionSettings: data,
      },
    };

    try {
      const response = await updateProject(projectId, payload);

      if (response.status === 200) {
        fetchSettings();
        message.success("Settings updated");
        onClose();
      }
    } catch (error) {
      message.error("Failed to update settings");
      console.error(error);
    }
  };

  return (
    <Form
      inputField={interruptionSettings}
      template={fields}
      schema={schema}
      loading={loading}
      setLoading={setLoading}
      onSubmit={handleSave}
      buttonInfo={{ label: "Save", style: "right-side" }}
    />
  );
}
