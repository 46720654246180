import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import { message } from "antd";
import * as projectService from "../../../services/projectService";

function EditExtraction({
  projectId,
  extraction,
  fetchExtractions,
  item,
  index,
  handleSecondary,
}) {
  const [inputField, setInputField] = useState({
    parameterName: item ? item.parameterName : "",
    parameterDescription: item ? item.parameterDescription : "",
    parameterChoices: item ? item.parameterChoices : [],
    parameterType: item ? item.parameterType : "",
  });

  let [previewData, setPreviewData] = useState("");

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "parameterName",
      label: "Name of the extracted field",
      type: "text",
    },
    {
      name: "parameterType",
      label: "Choose type of field",
      type: "radio",
      options: [
        { id: 1, value: "boolean", label: "Yes/No Field" },
        { id: 2, value: "open", label: "Open answer" },
        { id: 3, value: "choice", label: "Choice field" },
      ],
    },

    {
      name: "parameterDescription",
      label: "Describe what the AI should extract.",
      type: "textArea",
      style: { height: "250px" },
    },
    ...((inputField.parameterType === "choice" && !previewData.parameterType) ||
    previewData.parameterType === "choice"
      ? [
          {
            name: "parameterChoices",
            label: "Provide a list of options for the AI to select from",
            type: "inputFieldList",
          },
        ]
      : []),
  ];

  const buttonInfo = {
    style: "",
    buttons: "2",
    primaryLabel: "Save",
    secondaryLabel: "Cancel",
  };

  const schema = {
    parameterName: Joi.string().required().max(50).label("Name"),
    parameterDescription: Joi.string().required().max(300).label("Description"),
    parameterType: Joi.string().required().max(300).label("Type of field"),
    parameterChoices: Joi.array().items(Joi.string()).required(),
  };

  const handleYes = async (req) => {
    const newArray = extraction.extraction.extractionParameters;

    if (index >= 0 && index < newArray.length) {
      // Replace the element at the specified index with the values from req
      newArray[index] = req;
    }

    let data = {};
    data.extractionParameters = [...newArray];

    try {
      const response = await projectService.createExtraction(
        projectId,
        data
      );

      if (response.status === 200) {
        message.success("Field updated");
        fetchExtractions(projectId);
        setLoading(false);
        handleSecondary();
        // setTabState("Settings");
        // setTypeofChoice("");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
      setLoading(false);
    }
  };

  const getPreviewDataInParent = (dataDuplicate) => {
    if (!dataDuplicate) {
      setPreviewData("");
    }
    setPreviewData(dataDuplicate);
  };

  return (
    <Form
      key={JSON.stringify(inputField)}
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={buttonInfo}
      onSubmit={handleYes}
      loading={loading}
      setLoading={setLoading}
      handleSecondary={handleSecondary}
      getValue={getPreviewDataInParent}
    />
  );
}

export default EditExtraction;
