import http from "./httpService";
const apiEndpoint = "/webhookstatus";

/*-------- whitelabel --------------------*/
export function getAllWebhooks(projectId) {
  return http.get(apiEndpoint + "/whitelabelGetWebhookStatus/" + projectId);
}

/*------------- --------------------*/

export function retyWebhooks(statusId, projectId) {
  return http.post(apiEndpoint + "/retry/" + projectId + "/" + statusId);
}

export function manualRetry(projectId, conversation) {
  return http.post(apiEndpoint + "/whitelabelManualRetry/" + projectId + "/" + conversation);
}


