function getPhoneAgent(humanAgent) {
  const { name, description, searchTerm, contactMedium, phoneNumber } =
    humanAgent;
  return {
    name,
    description,
    searchTerm,
    contactMedium,
    phoneNumber,
  };
}

function getWhatsappAgent(humanAgent) {
  const { name, contactMedium, email, phoneNumber } = humanAgent;
  return {
    name,
    contactMedium,
    email,
    phoneNumber
  };
}

export {
    getPhoneAgent,
    getWhatsappAgent
}
