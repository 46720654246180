import React, { useState, useEffect } from "react";
import { message } from "antd";
import * as projectService from "../../../services/projectService";
import { Switch } from "../../../common/switch";

function EnableAutoCallEnd({ projectId, settings, fetchSettings }) {
  const [autoCallEndEnabled, setAutoCallEndEnabled] = useState(false);

  useEffect(() => {
    setAutoCallEndEnabled(settings?.voiceChatSettings?.autoCallEndEnabled);
  }, [settings]);

  const handleToggle = async () => {
    setAutoCallEndEnabled(!autoCallEndEnabled);

    const data = {
      autoCallEndEnabled: !autoCallEndEnabled,
    };
    const response = await projectService.updateProject(projectId, {
      voiceChatSettings: data,
    });
    if (response.status === 200) {
      message.success("Changes saved");
      fetchSettings(projectId);
    }
  };

  return (
    <Switch
      color="emerald"
      id={`checkbox-${autoCallEndEnabled}`}
      checked={autoCallEndEnabled}
      onClick={handleToggle}
    />
  );
}

export default EnableAutoCallEnd;
