import React, { useState } from "react";

import { downloadExtractions } from "../../../services/conversationService";
import { message } from "antd";
import dayjs from "dayjs";

import { Button } from "../../../common/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "../../../common/dialog";
import { DialogTitleWithClose } from "../../HomeModule/CustomAPIs/components/Dialog/DialogTitleWithClose";
import { StartAndEndDateField } from "../components/StartAndEndDateField";
import { ConversationSourcesSelect } from "../components/ConversationSourcesSelect";

import { useDateRangeAndConverastionSource } from "../models/useDateRangeAndConversationSource";
import { createLinkAndDownloadAsCSV } from "../helpers/create_download_as_csv";

export function ExtractionsDownloader(props) {
  const { isOpen, onClose, projectId, showDownload } = props;
  const {
    dateRange,
    setDateRange,
    conversationSource,
    setConversationSource,
    error,
    setError,
    validate,
    generateFetchConversationsPayload,
  } = useDateRangeAndConverastionSource();

  const [loading, setLoading] = useState(false);

  const downloadExtraction = async () => {
    if (!validate()) {
      return;
    }
    setLoading(true);
    message.loading("Downloading your extracted data..")

    try {
      const payload = generateFetchConversationsPayload();
      const response = await downloadExtractions({
        projectId,
        body: JSON.stringify(payload),
      });

      const data = response.data;

      if (data === "") {
        setError({
          isError: true,
          message: "No extractions found for the date range",
        });
        return;
      }

      const fileName = `${projectId}_extractions_${dayjs(
        Number(dateRange.startDate)
      ).format("YYYY-MM-DD")}-${dayjs(Number(dateRange.endDate)).format(
        "YYYY-MM-DD"
      )}`;

      createLinkAndDownloadAsCSV(data, fileName);

      message.success(`Downloaded to ${fileName}.csv`);
    } catch (error) {
      message.error(error.response.data.msg);
      console.error(error);
    }
    setLoading(false);
    onClose();
  };

  return (
    <>
      {isOpen && (
        <Dialog size="lg" open={isOpen} onClose={onClose}>
          <DialogTitle>
            <DialogTitleWithClose
              title={`Download Extractions`}
              onClose={onClose}
            />
          </DialogTitle>
          <DialogBody>
            <div className="flex flex-col gap-y-8">
              <div>
                <StartAndEndDateField
                  error={error}
                  setError={setError}
                  setDate={setDateRange}
                />
              </div>
              <ConversationSourcesSelect
                selectedSource={conversationSource}
                onSelectSource={setConversationSource}
              />
            </div>
          </DialogBody>
          <DialogActions>
            <Button outline onClick={onClose}>
              Cancel
            </Button>
            <Button
              loading={loading}
              disabled={!validate() || error.isError}
              onClick={downloadExtraction}
            >
              Download
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
