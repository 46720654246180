import React from "react";

import { Description, Field, Label } from "../../../common/fieldset";

import { CONVERSATION_SOURCE } from "../models/conversation_source";
import {
  Checkbox,
  CheckboxField,
  CheckboxGroup,
} from "../../../common/checkbox";

export function ConversationSourcesSelect(props) {
  const { selectedSource, onSelectSource } = props;

  return (
    <Field>
      <Label>Conversation sources</Label>
      <Description>Pick the sources to download from</Description>
      <CheckboxGroup className="grid grid-cols-2 border py-2 px-4 rounded-lg">
        {Object.values(CONVERSATION_SOURCE).map((source_, id) => {
          const sourceIdx = selectedSource?.findIndex(
            (source) => source === source_.id
          );
          const isSelected = sourceIdx >= 0;
          let handleOnChange;

          const checkSelectedSource = () =>
            onSelectSource([...selectedSource, source_.id]);

          const uncheckSelectedSource = () =>
            onSelectSource(selectedSource.toSpliced(sourceIdx, 1));

          handleOnChange = isSelected
            ? uncheckSelectedSource
            : checkSelectedSource;

          return (
            <CheckboxField key={source_.id}>
              <Checkbox
                name={source_.label}
                value={isSelected}
                onChange={handleOnChange}
              />
              <Label>{source_.label}</Label>
            </CheckboxField>
          );
        })}
      </CheckboxGroup>
    </Field>
  );
}
