import http from "./httpService";
const apiEndpoint = "/customerplan";


export function allPlans(companyId) {
    return http.get(apiEndpoint + "/whitelabelGetPricingPlans" + "/" + companyId);
}



