import { useState, useEffect } from "react";

import { getWhatsAppConfig } from "v2/services/whatsappService";

export function useWhatsappConfiguration(projectId) {
    const [whatsappConfiguration, setWhatsappConfiguration] = useState({
      data: null,
      loading: true,
      isConfigured: false,
    });
  
    const fetchWhatsappConfiguration = async (projectId) => {
      setWhatsappConfiguration((prevState) => ({ ...prevState, loading: true }));
      try {
        const response = await getWhatsAppConfig(projectId);
        const data = response.data;
        setWhatsappConfiguration({ data, loading: false, isConfigured: true });
      } catch (error) {
        const response = error.response;
        if (response.status === 404 || response.status === 401) {
          setWhatsappConfiguration((prevState) => ({
            ...prevState,
            loading: false,
            isConfigured: false,
          }));
        }
        console.error(error);
      }
    };
  
    useEffect(() => {
      if (projectId) {
        fetchWhatsappConfiguration(projectId);
      }
    }, [projectId]);
  
    return {
      whatsappConfiguration,
      fetchWhatsappConfiguration,
    };
  }