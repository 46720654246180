import React, { useState } from "react";
import { Dialog, DialogBody } from "../../../../common/dialog";
import { DialogTitleWithClose } from "../../../../components/DialogTitleWithClose";
import { Button } from "../../../../common/button";
import { PlusIcon } from "@heroicons/react/16/solid";
import Joi from "joi-browser";
import * as webhookService from "../../../../services/webhookService";
import { message } from "antd";
import Form from "../../../../components/Form";

export function CreateWebhookDialog({ projectId, onSubmit }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Button className="" onClick={() => setIsOpen(true)}>
        <PlusIcon /> webhook
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitleWithClose title="Create Webhook" onClose={handleClose} />
        <DialogBody>
          <CreateWebhookForm
            projectId={projectId}
            setIsOpen={setIsOpen}
            handleClose={handleClose}
            onSubmit={onSubmit}
          />
        </DialogBody>
      </Dialog>
    </>
  );
}

export function CreateWebhookForm({
  projectId,
  setIsOpen,
  handleClose,
  onSubmit,
}) {
  const [inputField] = useState({
    eventName: "",
    endpointUrl: "",
    authKey: "",
    authRequired: false,
    webhookName: "",
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    { name: "webhookName", label: "Name", type: "text" },
    {
      name: "eventName",
      label: "Event Type",
      type: "select",
      options: [
        { id: 0, value: "", label: "No selection" },
        {
          id: 1,
          value: "captureEvent",
          label: "Capture details from conversation",
        },
      ],
    },

    {
      name: "endpointUrl",
      label: "Endpoint URL",
      type: "text",
    },

    {
      name: "authRequired",
      label: "Auth required",
      description: "Only enable it if Auth is required",
      type: "toggle",
    },

    {
      name: "authKey",
      label: "Auth Key",
      type: "text",
    },
  ];

  const buttonInfo = {
    style: "",
    buttons: "2",
    primaryLabel: "Create A Webhook",
    secondaryLabel: "Close",
  };

  const schema = {
    webhookName: Joi.string().allow(null, ""),
    eventName: Joi.string().required().label("event name"),
    endpointUrl: Joi.string().required().label("endpointUrl"),
    authRequired: Joi.boolean().label("Auth required"),
    authKey: Joi.string().allow(null, "").label("authKey"),
  };

  const handleCreate = async (data) => {
    setLoading(true);
    try {
      const response = await webhookService.subscribetoWebhook({
        projectId,
        ...data,
      });
      if (response.status === 201 || response.status === 200) {
        message.success("Success");
        setLoading(false);
        setIsOpen(false);
        onSubmit();
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsOpen(false);
      message.error("request failed");
      setLoading(false);
    }
  };

  return (
    <Form
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={buttonInfo}
      onSubmit={handleCreate}
      loading={loading}
      setLoading={setLoading}
      handleSecondary={handleClose}
    />
  );
}
