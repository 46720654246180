import http from "./httpService";
const apiEndpoint = "/campaigns";

function getAllCalls(projectId) {
  return http.get(`${apiEndpoint}/getAllWhiteLabelCampaigns/${projectId}`);
}

function addToCampaign(projectId, data) {
  return http.post(
    `${apiEndpoint}/whiteLabelCreateCampaign/${projectId}`,
    data
  );
}

function bulkUpload(projectId, data) {
  return http.post(
    `${apiEndpoint}/whiteLabelCampaignBatchUpload/${projectId}`,
    data
  );
}

function deleteFromCampaign({projectId, campaignId}) {
  return http.delete(`${apiEndpoint}/deleteWhiteLabelCampaign/${projectId}/${campaignId}`)
}



export {
  getAllCalls,
  addToCampaign,
  bulkUpload,
  deleteFromCampaign
}
