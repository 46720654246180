import { useState, useEffect } from "react";
import {
  Dialog,
  DialogBody,
  DialogTitle,
  DialogActions,
} from "v2/common/dialog";
import { Field, Label, Description } from "v2/common/fieldset";
import { Input } from "v2/common/input";
import { Switch } from "v2/common/switch";
import { updateProject } from "v2/services/projectService";
import { message } from "antd";
import { Button } from "v2/common/button";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";

import { DialogTitleWithClose } from "v2/HomePages/HomeModule/CustomAPIs/components/Dialog/DialogTitleWithClose";

export function WhatsAppSettings(props) {
  const { settings, fetchSettings, projectId } = props;

  const [open, setOpen] = useState(false);
  const [textChatSettings, setTextChatSettings] = useState({
    whatsAppHumanDelay: 0,
    whatsAppXFerOnMediaMessage: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (settings?.textChatSettings) {
      const { whatsAppXFerOnMediaMessage, whatsAppHumanDelay } =
        settings.textChatSettings;
      setTextChatSettings({
        whatsAppHumanDelay,
        whatsAppXFerOnMediaMessage,
      });
    }
  }, [settings]);

  const handleClose = () => setOpen(false);

  const handleSave = async () => {
    setLoading(true);
    try {
      const response = await updateProject(projectId, {
        textChatSettings: {
          ...settings.textChatSettings,
          ...textChatSettings,
        },
      });

      if (response.status === 200) {
        fetchSettings(projectId);
        message.success("Settings Updated");
        handleClose();
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to update settings");
      setLoading(false);
    }
  };

  const isChecked = textChatSettings.whatsAppXFerOnMediaMessage;
  const humanDelay = textChatSettings.whatsAppHumanDelay;

  const toggleXFerOnMediaMessage = () => {
    setTextChatSettings((prevSettings) => ({
      ...prevSettings,
      whatsAppXFerOnMediaMessage: !prevSettings.whatsAppXFerOnMediaMessage,
    }));
  };

  const updateHumanDelay = (e) => {
    setTextChatSettings((prevSettings) => ({
      ...prevSettings,
      whatsAppHumanDelay: e.target.value,
    }));
  };

  return (
    <>
      <Button outline onClick={() => setOpen(true)}>
        <Cog6ToothIcon />
        Settings
      </Button>
      <Dialog size="lg" open={open} onClose={handleClose}>
        <DialogTitle>
          <DialogTitleWithClose title="Settings" onClose={handleClose} />
        </DialogTitle>
        <DialogBody>
          <div className="flex flex-col gap-y-8">
            <div className="flex justify-between">
              <Field>
                <Label>Enable Transfer to Human Agent on Media Message</Label>
                <Description>
                  Transfer to a human agent, if a document, audio or image is received
                </Description>
              </Field>
              <Switch
                color="emerald"
                checked={isChecked}
                onClick={toggleXFerOnMediaMessage}
              />
            </div>
            <Field>
              <Label>Message Delay (in seconds)</Label>
              <Description>Responds after X seconds has elapsed</Description>
              <Input
                type="number"
                value={humanDelay}
                onChange={updateHumanDelay}
                min={0}
                max={180}
              />
            </Field>
          </div>
        </DialogBody>
        <DialogActions>
          <Button outline onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleSave} loading={loading}>
            Save Settings
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
