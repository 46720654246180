import { Button } from "v2/common/button";
import { Agents } from "./Agents";
import { AddAgent } from "./Add_agent";
import ConfigureAgentTransfer from "./Configure_transfer";
import EnableAgentTransfer from "./Enable_Agent_transfer";

export function PhoneCallAgents(props) {
  const { projectId, refetchAgents, agents, settings, refetchSettings } = props;

  return (
    <div className="w-full">
      <div className="w-full flex justify-between p-2">
        <div className="flex items-end gap-x-2">
          <h3 className="font-semibold text-md">Phone Call Agents</h3>
          <div className="self-end">
            <EnableAgentTransfer
              projectId={projectId}
              settings={settings}
              refetchSettings={refetchSettings}
            />
          </div>
        </div>

        <div className="flex gap-x-4">
          <ConfigureAgentTransfer
            projectId={projectId}
            settings={settings}
            humanAgents={agents}
            refetchHumanAgents={refetchAgents}
          />
          <AddAgent projectId={projectId} refetchAgents={refetchAgents} />
        </div>
      </div>
      <div>
        <Agents
          agents={agents}
          refetchAgents={refetchAgents}
          projectId={projectId}
        />
      </div>
    </div>
  );
}
