import React from "react";
import { useOutletContext } from "react-router-dom";
import ParentExtractionModule from "v2/HomePages/HomeModule/Extraction/ParentExtractionModule";

export function Extractions() {
  const {projectId } = useOutletContext();

  return (
    <ParentExtractionModule projectId={projectId} />
  );
}
