import { useState } from "react";
import dayjs from "dayjs";

export function useDateRangeAndConverastionSource() {
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });

  const [conversationSource, setConversationSource] = useState([]);

  const [error, setError] = useState({
    isError: false,
    message: "",
  });

  const validate = () => {
    return (
      dayjs(dateRange.startDate).isValid() &&
      dayjs(dateRange.endDate).isValid() &&
      conversationSource.length > 0
    );
  };

  const generateFetchConversationsPayload = () => {
    return {
      ...dateRange,
      conversationSources: conversationSource,
    };
  };

  return {
    dateRange,
    setDateRange,
    conversationSource,
    setConversationSource,
    error,
    setError,
    validate,
    generateFetchConversationsPayload,
  };
}