import { useState } from "react";

import Form from "v2/components/Form";

export const addNewAgentButtons = {
  buttons: "2",
  primaryLabel: "Add Agent",
  secondaryLabel: "Cancel",
};

export const editAgentButtons = {
  buttons: "2",
  primaryLabel: "Update Agent",
  secondaryLabel: "Cancel",
};

export function AgentForm({
  agent,
  agentFormTemplate,
  agentFormSchema,
  onSubmit,
  onCancel,
  buttons,
  loading,
  setLoading,
}) {

  const [errorMessage] = useState({});

  return (
    <Form
      errorMessage={errorMessage}
      inputField={agent}
      template={agentFormTemplate}
      schema={agentFormSchema}
      buttonInfo={buttons}
      onSubmit={onSubmit}
      loading={loading}
      setLoading={setLoading}
      handleSecondary={onCancel}
    />
  );
}
