import { addToWhatsAppThread } from "v2/services/whatsappService";

export function useWhatsappSend() {
  const sendWhatsappMessage = async (projectId, data, onSuccess) => {
    try {
      const response = await addToWhatsAppThread(projectId,data);
      if (response.status === 200) {
        onSuccess();
        console.log("success");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { sendWhatsappMessage };
}
