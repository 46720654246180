import http from "./httpService";
const apiEndpoint = "/whatsapp";

function manageWhatsapp(projectId, req) {
  return http.post(
    apiEndpoint + "/manage/whitelabelRegister/" + projectId,
    req
  );
}

function getWhatsAppConfig(projectId) {
  return http.get(`${apiEndpoint}/manage/whitelabelConfig/${projectId}`);
}

function deleteWhatsAppConfig(projectId) {
  return http.delete(
    `${apiEndpoint}/manage/whitelabelDeleteConfig/${projectId}`
  );
}

function getTemplates(projectId) {
  return http.get(`${apiEndpoint}/manage/whitelabelGetTemplates/${projectId}`);
}

function addWhatsAppPhoneNumber(projectId, data) {
  return http.post(
    `/whatsapp/manage/whitelabelAddPhone/${projectId}`,
    JSON.stringify(data),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

function addToWhatsAppThread(projectId, data) {
  return http.post(
    `/whatsapp/manage/whitelabelAddToWhatsAppThread/${projectId}`,
    JSON.stringify(data),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export {
  manageWhatsapp,
  getWhatsAppConfig,
  deleteWhatsAppConfig,
  getTemplates,
  addWhatsAppPhoneNumber,
  addToWhatsAppThread
};
