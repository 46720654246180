import React, { useState, useEffect } from 'react';
import { Input as HeadlessInput } from '@headlessui/react';
import { clsx } from 'clsx';

const dateTypes = ['date', 'datetime-local', 'month', 'time', 'week'];

const Input = React.forwardRef(function Input({ className, ...props }, ref) {
  return (
    <span
      data-slot="control"
      className={clsx([
        className,
        'relative block w-full',
        'before:absolute before:inset-px before:rounded-[calc(theme(borderRadius.lg)-1px)] before:bg-white before:shadow',
        'dark:before:hidden',
        'after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset after:ring-transparent sm:after:focus-within:ring-2 sm:after:focus-within:ring-blue-500',
        'has-[[data-disabled]]:opacity-50 before:has-[[data-disabled]]:bg-zinc-950/5 before:has-[[data-disabled]]:shadow-none',
        'before:has-[[data-invalid]]:shadow-red-500/10',
      ])}
    >
      <HeadlessInput
        ref={ref}
        className={clsx([
          props.type &&
            dateTypes.includes(props.type) && [
              '[&::-webkit-datetime-edit-fields-wrapper]:p-0',
              '[&::-webkit-date-and-time-value]:min-h-[1.5em]',
              '[&::-webkit-datetime-edit]:inline-flex',
              '[&::-webkit-datetime-edit]:p-0',
              '[&::-webkit-datetime-edit-year-field]:p-0',
              '[&::-webkit-datetime-edit-month-field]:p-0',
              '[&::-webkit-datetime-edit-day-field]:p-0',
              '[&::-webkit-datetime-edit-hour-field]:p-0',
              '[&::-webkit-datetime-edit-minute-field]:p-0',
              '[&::-webkit-datetime-edit-second-field]:p-0',
              '[&::-webkit-datetime-edit-millisecond-field]:p-0',
              '[&::-webkit-datetime-edit-meridiem-field]:p-0',
            ],
          'relative block w-full appearance-none rounded-lg px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] sm:px-[calc(theme(spacing[3])-1px)] sm:py-[calc(theme(spacing[1.5])-1px)]',
          'text-base/6 text-zinc-950 placeholder:text-zinc-500 sm:text-sm/6 dark:text-white',
          'border border-zinc-950/10 data-[hover]:border-zinc-950/20 dark:border-white/10 dark:data-[hover]:border-white/20',
          'bg-transparent dark:bg-white/5',
          'focus:outline-none',
          'data-[invalid]:border-red-500 data-[invalid]:data-[hover]:border-red-500 data-[invalid]:dark:border-red-500 data-[invalid]:data-[hover]:dark:border-red-500',
          'data-[disabled]:border-zinc-950/20 dark:data-[hover]:data-[disabled]:border-white/15 data-[disabled]:dark:border-white/15 data-[disabled]:dark:bg-white/[2.5%]',
        ])}
        {...props}
      />
    </span>
  );
});

const InputFieldList = ({ name, value, onChange }) => {
  const [fields, setFields] = useState(value || ['']);

  useEffect(() => {
    setFields(value || ['']);
  }, [value]);

  const handleAddField = (e) => {
    e.preventDefault();
    const newFields = [...fields, ''];
    setFields(newFields);
    if (onChange) {
      onChange({ currentTarget: { name }, listValues: newFields });
    }
  };

  const handleRemoveField = (index, e) => {
    e.preventDefault();
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
    if (onChange) {
      onChange({ currentTarget: { name }, listValues: newFields });
    }
  };

  const handleChange = (index, event) => {
    const newFields = [...fields];
    newFields[index] = event.target.value;
    setFields(newFields);
    if (onChange) {
      onChange({ currentTarget: { name }, listValues: newFields });
    }
  };

  return (
    <div className="p-4">
      {fields.map((field, index) => (
        <div key={index} className="flex items-center mb-2">
          <Input
            type="text"
            value={field}
            onChange={(e) => handleChange(index, e)}
            className="flex-1"
            placeholder={`Option ${index + 1}`}
          />
          <button
            onClick={(e) => handleRemoveField(index, e)}
            className={`ml-2 ${fields.length === 1 ? 'opacity-50 cursor-not-allowed' : 'text-red-500 hover:text-red-700'}`}
            disabled={fields.length === 1}
          >
            &times;
          </button>
        </div>
      ))}
      <button
        onClick={handleAddField}
        className="text-blue-500 hover:text-blue-700 mt-2"
      >
        + Add option
      </button>
    </div>
  );
};

export default InputFieldList;
