import GHL from "v2/HomePages/HomeIntegrations/GHL";
import { IntegrationShell } from "../common/Shell";

import { useOutletContext } from "react-router-dom";

export function GHLIntegration() {
  const { projectId } = useOutletContext();
  return (
    <IntegrationShell>
      <GHL projectId={projectId} />
    </IntegrationShell>
  );
}
