import React, { useState, useEffect } from "react";
import { message } from "antd";
import * as projectService from "../../../services/projectService";
import { Switch } from "../../../common/switch";

function EnableCustomAction({
  projects,
  activeProject,
  settings,
  refetchSettings,
}) {
  const [customActionsEnabled, setCustomActionsEnabled] = useState(false);

  useEffect(() => {
    setCustomActionsEnabled(settings?.RAGSettings?.customActionsEnabled);
  }, [settings]);


  const handleToggle = async () => {
    setCustomActionsEnabled(!customActionsEnabled);

    const data = {
      customActionsEnabled: !customActionsEnabled,
    };
    const response = await projectService.updateProject(
      projects[activeProject]._id,
      { RAGSettings: data }
    );
    if (response.status === 200) {
      message.success("Changes saved");
      refetchSettings();
    }
  };

  return (
      <Switch
        color="emerald"
        id={`checkbox-${customActionsEnabled}`}
        checked={customActionsEnabled}
        onClick={handleToggle}
      />
  );
}

export default EnableCustomAction;
