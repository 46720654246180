import {
  Dropdown,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
  DropdownLabel,
} from "v2/common/dropdown";
import { Avatar } from "v2/common/avatar";
import { SidebarItem } from "v2/common/sidebarCatalyst";

import {
  UserIcon,
  ArrowRightStartOnRectangleIcon,
  ChevronUpIcon,
} from "@heroicons/react/16/solid";

export function UserMenu(props) {
  const {  user } = props;

  const userMenuItems = [
    // ...(user?.isCompanyOwner
    //   ? [{ name: "Profile", href: `/profile`, icon: <UserIcon /> }]
    //   : []),
    {
      name: "Logout",
      href: `/logout`,
      icon: <ArrowRightStartOnRectangleIcon />,
    },
  ];

  const initials = (user?.name && user.name[0]) ?? user?.email[0];

  return (
    <Dropdown>
      <DropdownButton as={SidebarItem}>
        <span className="flex min-w-0 items-center gap-3">
          <Avatar
            initials={initials}
            className="size-10"
            alt=""
          />
          <span className="min-w-0">
            <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
              {user?.name ?? user?.email}
            </span>
          </span>
        </span>
        <ChevronUpIcon />
      </DropdownButton>
      <DropdownMenu className="min-w-64">
        {userMenuItems.map((item) => (
          <DropdownItem to={item.href}>
            {item.icon}
            <DropdownLabel>{item.name}</DropdownLabel>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
