import { useDeleteConversation } from "../../../Threads/models/useDeleteConversation";

import {
  Alert,
  AlertTitle,
  AlertDescription,
  AlertBody,
  AlertActions,
} from "../../../../../common/alert";
import { Button } from "../../../../../common/button";

export function ConversationDeletion(props) {
  const { isOpen, onClose, fetchConversations, conversationId, projectId } =
    props;

  const { loading, handleDeleteConversation } =
    useDeleteConversation(fetchConversations, onClose);

  return (
    <Alert open={isOpen} onClose={onClose} size="sm">
      <AlertTitle>Delete Conversation</AlertTitle>
      <AlertDescription>
        Once deleted, this conversation and the call recordings will be
        completely removed from our system.
      </AlertDescription>
      <AlertBody style={{ display: "flex", alignItems: "center" }}></AlertBody>
      <AlertActions>
        <Button plain onClick={onClose}>
          Cancel
        </Button>
        <Button
          loading={loading}
          onClick={() => handleDeleteConversation(projectId, conversationId)}
        >
          OK
        </Button>
      </AlertActions>
    </Alert>
  );
}
