import React, { useEffect, useState } from "react";

import { TableComponent } from "../HomeModule/CustomAPIs/components/TableComponent";
import { CreateContact } from "./New_contact";
import { EditContact } from "./Edit_contact";
import { DeleteContact } from "./Delete_contact";

const getTime = (data) => {
  const epochTime = data;
  const date = new Date(epochTime);
  const dateString = date.toLocaleString(); // or use toUTCString() or toDateString() as needed
  return dateString;
};

export function Contact(props) {
  const { contacts, fetchContacts, projectId } = props;
  const [action, setAction] = useState({
    actionMode: "",
    isOpen: "",
    actionItem: "",
  });

  const onEdit = (contact) => {
    setAction({
      actionMode: "edit",
      isOpen: true,
      actionItem: contact,
    });
  };

  const onDelete = (contact) => {
    setAction({
      actionMode: "delete",
      isOpen: true,
      actionItem: contact,
    });
  };

  const onClose = () => {
    setAction({
      actionMode: "",
      isOpen: false,
      actionItem: "",
    });
  };

  return (
    <div className="h-full w-full overflow-auto">
      <div className="p-8">
        <div className="flex  justify-between mb-8">
          <p className="font-bold text-2xl">Contacts</p>
          <CreateContact fetchContacts={fetchContacts} projectId={projectId} />
        </div>
        <div className="border rounded-lg">
          <TableComponent
            data={contacts}
            headers={[
              {
                label: "First Name",
                key: "firstName",
              },
              {
                label: "Last Name",
                key: "lastName",
              },
              {
                label: "Phone Number",
                key: "phone",
              },
              {
                label: "Email",
                key: "email",
              },
              {
                label: "Created At",
                key: "createdAt",
                format: (value) => getTime(value),
              },
            ]}
            onEdit={onEdit}
            onRowClick={() => {}}
            onDelete={onDelete}
          />
        </div>
      </div>
      {action.actionMode === "edit" && (
        <EditContact
          open={action.isOpen}
          contact={action.actionItem}
          onClose={onClose}
          fetchContacts={fetchContacts}
          projectId={projectId}
        />
      )}
      {action.actionMode === "delete" && (
        <DeleteContact
          open={action.isOpen}
          contact={action.actionItem}
          onClose={onClose}
          fetchContacts={fetchContacts}
          projectId={projectId}
        />
      )}
    </div>
  );
}
