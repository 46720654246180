import React, { useState, useEffect } from "react";
import { Checkbox, CheckboxField, CheckboxGroup } from "../../common/checkbox";
import Joi from "joi-browser";
import { Button } from "../../common/button";
import { Description, Label } from "../../common/fieldset";
import { Switch, SwitchField } from "../../common/switch";
import Form from "../../components/Form";
import * as thirdPartyServices from "../../services/thirdpartyService";
import { message } from "antd";
import { useOutletContext } from "react-router-dom";

function Recording() {
  const { projects, activeProject } = useOutletContext();
  const [inputField, setInputField] = useState({
    recordingEnabled: "",
    disclaimerEnabled: "",
    initialMessageText: "",
    initialMessageURL: "",
  });

  let [previewData, setPreviewData] = useState("");

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "recordingEnabled",
      label: "Recording",
      description:
        "All audio recordings are now conveniently attached to their respective discussion threads for easy reference and review. You have the option to disable this feature if you prefer not to record any calls.",
      type: "toggle",
    },
    {
      name: "disclaimerEnabled",
      label: "Play Disclaimer",
      description:
        "This toggle enables or disables the automatic playback of a pre-recorded disclaimer at the beginning of a call or session.",
      type: "toggle",
    },
  ];

  if (
    (typeof previewData.disclaimerEnabled === "undefined" ||
      previewData.disclaimerEnabled === "") &&
    inputField.disclaimerEnabled === true
  ) {
    template.push(
      {
        name: "initialMessageText",
        label: "Disclaimer text",
        description:
          "Write text here that will be converted into a voice note for the Disclaimer. Please fill in this field or provide a disclaimer voice URL, not both.",
        type: "textArea",
        style: { height: "150px" },
      },
      {
        name: "initialMessageURL",
        label: "Disclaimer Voice URL",
        description:
          "Paste the Voice URL here for the Disclaimer. Please fill in this field or provide a Disclaimer text above, not both.",
        type: "text",
      }
    );
  } else if (previewData.disclaimerEnabled) {
    template.push(
      {
        name: "initialMessageText",
        label: "Disclaimer text",
        description:
          "Write text here that will be converted into a voice note for the Disclaimer. Please fill in this field or provide a disclaimer voice URL, not both.",
        type: "textArea",
        style: { height: "150px" },
      },
      {
        name: "initialMessageURL",
        label: "Disclaimer Voice URL",
        description:
          "Paste the Voice URL here for the Disclaimer. Please fill in this field or provide a Disclaimer text above, not both.",
        type: "text",
      }
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (projects[activeProject]._id) {
          const response = await thirdPartyServices.getthirdpartyData(
            projects[activeProject]._id
          );
          const { recordingEnabled } =
            response.data.telephonySettings.twilioSettings;
          const { initialMessageText, initialMessageURL, disclaimerEnabled } =
            response.data.telephonySettings.twilioSettings
              .initialMessageSettings;
          const initialMessageSettings = {
            recordingEnabled,
            disclaimerEnabled,
            initialMessageText,
            initialMessageURL,
          };
          setInputField(initialMessageSettings);
        }
      } catch (error) {}
    };
    fetchData();
  }, [projects, activeProject]);

  const buttonInfo = { label: "Submit", style: "right-side" };

  const schema = {
    recordingEnabled: Joi.boolean().allow(null, "").label("Recording"),
    disclaimerEnabled: Joi.boolean().allow(null, "").label("Disclaimer"),
    initialMessageText: Joi.string()
      .allow(null, "")
      .label("initialMessageText"),
    initialMessageURL: Joi.string().allow(null, "").label("initialMessageURL"),
  };

  const handleRecording = async (data) => {
    let telephonySettings = {
      telephonySettings: {
        telephonyProvider: "twilio",
        twilioSettings: {
          recordingEnabled: data.recordingEnabled,
          initialMessageSettings: {
            initialMessageText: data.initialMessageText,
            initialMessageURL: data.initialMessageURL,
            disclaimerEnabled: data.disclaimerEnabled,
          },
        },
      },
    };

    try {
      const response = await thirdPartyServices.thirdpartyUpdate(
        telephonySettings,
        projects[activeProject]._id
      );
      if (response.status === 200) {
        message.success("Changes saved");
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false); // Ensure setLoading is defined and used correctly
        message.error(ex.response.data.msg);
        setLoading(false);
      }
      //   }
    }
  };

  const getPreviewDataInParent = (dataDuplicate) => {
    if (!dataDuplicate) {
      setPreviewData("");
    }
    setPreviewData(dataDuplicate);
  };
  {
    return (
      <div>
        <main className="px-8 py-6">
          <div className="">
            <div className="flex flex-col gap-y-2">
              <p className="font-bold text-lg">Recordings</p>
              <p className="font-medium text-sm text-gray-500"></p>
            </div>
            <div className="">
              <div className="overflow-visible rounded-lg border my-10">
                <div className="p-5">
                  <Form
                    key={JSON.stringify(inputField)}
                    errorMessage={errorMessage}
                    inputField={inputField}
                    template={template}
                    schema={schema}
                    buttonInfo={buttonInfo}
                    onSubmit={handleRecording}
                    loading={loading}
                    setLoading={setLoading}
                    getValue={getPreviewDataInParent}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Recording;
