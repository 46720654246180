import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../common/table";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../../../../common/alert";
import { TbCapture } from "react-icons/tb";

import { Button } from "../../../../common/button";
import * as webhookstatusService from "../../../../services/webhookstatusService";
import { message } from "antd";
import ReactJsonPretty from "react-json-pretty";

function WebhooksRunHistory({ projectId }) {
  const [webhook, SetWebhooks] = useState([]);
  const [data, setData] = useState({});
  const [item] = useState({});
  const [reload, setReload] = useState(false);

  let [isRetryOpen, setIsRetryOpen] = useState(false);
  let [isDataExtractedOpen, setIsDataExtractedOpen] = useState(false);

  const getTime = (data) => {
    if (data === 0) return "Never";

    const epochTime = data;
    const date = new Date(epochTime);
    const dateString = date.toLocaleString(); // or use toUTCString() or toDateString() as needed
    return dateString;
  };

  useEffect(() => {
    const fetchData = async (projectId) => {
      const { data } = await webhookstatusService.getAllWebhooks(projectId);
      const reversedData = data.response.reverse();
      SetWebhooks(reversedData);
    };

    if (projectId) {
      fetchData(projectId);
    }
  }, [reload, projectId]);

  const handleSuccess = () => {
    message.info(
      "This request was successfully posted. No further action can be performed"
    );
  };

  const handleRetry = async (item) => {
    try {
      const response = await webhookstatusService.manualRetry(
        item._id,
        projectId
      );

      if (response.status === 200) {
        setReload(!reload);
        message.success("Success");
        setIsRetryOpen(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsRetryOpen(false);
    }
  };

  const handleJSONModal = (item) => {
    setIsDataExtractedOpen(true);
    setData(item.extracted_data);
  };

  return (
    <div>
      <div>
        
          <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] p-5">
            <TableHead>
              <TableRow>
                <TableHeader>Webhook ID</TableHeader>
                <TableHeader>Data</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader>Timestamp</TableHeader>
              </TableRow>
            </TableHead>

            <TableBody>
              {webhook.length > 0 &&
                webhook.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className="font-medium">
                      {item.webhookId}
                    </TableCell>
                    <TableCell>
                      {" "}
                      <TbCapture
                        className="cursor-pointer h-5"
                        onClick={() => handleJSONModal(item)}
                      />
                    </TableCell>
                    <TableCell>
                      {item.status === "failed" ? (
                        <Button
                          onClick={() => setIsRetryOpen(true)}
                          color="rose"
                        >
                          {item.status}
                        </Button>
                      ) : item.status === "finished" ? (
                        <Button onClick={handleSuccess} color="lime">
                          {item.status}
                        </Button>
                      ) : (
                        <Button outline color="light">
                          {item.status}
                        </Button>
                      )}
                    </TableCell>

                    <TableCell>
                      <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                        {getTime(item.timestamp)}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

        <Alert open={isRetryOpen} onClose={setIsRetryOpen}>
          <AlertTitle>Retry webhook</AlertTitle>
          <AlertDescription>
            Please confirm, do you want to retry the webhook now?
          </AlertDescription>
          <AlertActions>
            <Button plain onClick={() => setIsRetryOpen(false)}>
              Cancel
            </Button>
            <Button onClick={() => handleRetry(item)}>Retry</Button>
          </AlertActions>
        </Alert>

        <Alert
          open={isDataExtractedOpen}
          size="5xl"
          onClose={setIsDataExtractedOpen}
        >
          <AlertTitle>Extracted Data</AlertTitle>
          <AlertBody>
            <div className="w-full overflow-auto p-4 border rounded-lg">
              <ReactJsonPretty json={data} />
            </div>
          </AlertBody>
          <AlertActions>
            <Button outline onClick={() => setIsDataExtractedOpen(false)}>
              Close
            </Button>
          </AlertActions>
        </Alert>
      </div>
    </div>
  );
}

export default WebhooksRunHistory;
