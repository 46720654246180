import { useState, useCallback } from "react";

import {
  getAllCustomAPIs,
  addCustomAPI,
  updateCustomAPI,
  deleteCustomAPI,
} from "../../../../services/modules/customAPI";


export function useCustomAPIs(projectId) {
  const [customApis, setCustomApis] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCustomApis = useCallback(async () => {
    try {
      const response = await getAllCustomAPIs({ projectId });
      const { customAPIs } = response.data;

      setCustomApis(customAPIs);
    } catch (error) {
      console.error("Error in fetching custom APIs", error);
    }
  }, []);

  const addCustomApi = useCallback(async (requestBody) => {
    try {
      const response = await addCustomAPI({ projectId, requestBody });
      const { customAPIs } = response.data;

      setCustomApis(customAPIs);
    } catch (error) {
      console.error("Error in adding custom API", error);
    }
  }, []);

  const updateCustomApi = useCallback(async (apiId, requestBody) => {
    try {
      const { status } = await updateCustomAPI({
        projectId,
        apiId,
        requestBody,
      });

      if (status === 200) {
        fetchCustomApis(projectId);
      }

    } catch (error) {
      console.error("Error in updating custom API", error);
    }
  }, []);

  const deleteCustomApi = useCallback(async (apiId) => {
    try {
      const { status } = await deleteCustomAPI({ projectId, apiId });

      if (status === 200 ) {
          fetchCustomApis(projectId);
      }
    } catch (error) {
      console.error("Error in deleting custom API", error);
    }
  }, []);

  return {
    customApis,
    fetchCustomApis,
    addCustomApi,
    updateCustomApi,
    deleteCustomApi,
  };
}
