export const individualAPI = {
    apiName: "",
    apiURL: "",
    apiMethod: "POST",
    apiTriggerPrompt: "",
    triggerEvent: "callStart",
    callOnce: true,
    backchannelMessage: "",
    successResponse: "",
    failureResponse: "",
    inputParams: [],
    returnParams: [],
  };