import React from "react";
import Configuration from "./Configuration";

function ParentTriggerSMS({
  projects,
  activeProject,
  settings,
  reload,
  setReload,
  refetchSettings,
}) {
  return (
    <Configuration
      projects={projects}
      activeProject={activeProject}
      settings={settings}
      reload={reload}
      setReload={setReload}
      refetchSettings={refetchSettings}
    />
  );
}

export default ParentTriggerSMS;
