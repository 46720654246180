import { useState, useEffect } from "react";
import { useDebouncedSearch } from "./useDebouncedSearch";
import {
  getConversationsBySearchTerm,
  getPaginatedConversations,
} from "v2/services/conversationService";

export function usePaginatedConversations(projectId) {
  const [state, setState] = useState({
    totalConversations: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: 50,
    conversations: [],
    loading: true,
  });

  const { searchTerm, updateSearchTerm } = useDebouncedSearch();

  const resetConversations = () =>
    setState({
      totalConversations: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 50,
      conversations: [],
      loading: true,
    });

  const setLoading = (loading) =>
    setState((prevState) => ({
      ...prevState,
      loading,
    }));

  const appendConversations = (data, loading) =>
    setState((prevState) => ({
      ...data,
      conversations: [...prevState.conversations, ...data.conversations],
      loading,
    }));

  const setConversations = (data, loading) =>
    setState({
      ...data,
      loading,
    });

  const setSearchConversations = (data, loading) =>
    setState({
      totalConversations: data.conversations.length,
      conversations: data.conversations,
      loading,
    });

  const fetchConversationsBySearchTerm = async () => {
    setLoading(true);

    try {
      const response = await getConversationsBySearchTerm({
        projectId,
        searchTerm,
      });
      const data = response.data;

      setSearchConversations(data, false);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPaginatedConversations = async (
    page = 1,
    pageSize = 50,
    loading = true
  ) => {
    setLoading(loading);

    try {
      const response = await getPaginatedConversations({
        projectId,
        page,
        pageSize,
      });

      if (response.status === 200) {
        const data = response.data;

        if (page === 1) {
          setConversations(data, false);
        } else {
          appendConversations(data, false);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const loadMoreConversations = () => {
    if (state.currentPage < state.totalPages) {
      fetchPaginatedConversations(state.currentPage + 1, state.pageSize, false);
    }
  };

  const showLoadMoreConversation =
    searchTerm === "" &&
    !state.loading &&
    state.currentPage != state.totalPages &&
    state.totalPages != 0;

  return {
    ...state,
    fetchPaginatedConversations,
    loadMoreConversations,
    showLoadMoreConversation,
    updateSearchTerm,
    searchTerm,
    fetchConversationsBySearchTerm,
    resetConversations,
  };
}
