import { useState , useEffect} from "react";
import { getTemplates } from "v2/services/whatsappService";

export function useWhatsappTemplates(projectId) {
    const [templates, setTemplates] = useState({
        data: [],
        loading: true
    });

    const fetchTemplates = async (projectId) => {
        setTemplates((prevState) => ({...prevState, loading: true}));
        try {
            const response = await getTemplates(projectId);
            const templates = response.data;
            setTemplates({
                data: templates,
                loading: false
            })
        } catch (error) {
            setTemplates((prevState) => ({
                ...prevState,
                loading: false
            }));
            console.error(error);
        }
    }

    useEffect(() => {
        if (projectId) {
            fetchTemplates(projectId);
        }
    }, [projectId]);

    return {
        templates,
        fetchTemplates
    }
}