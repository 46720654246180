import http from "./httpService";
const apiEndpoint = "/widget";

export function addWidget(projectId) {
  return http.post(`${apiEndpoint}/whitelabelCreateWidget/${projectId}`, {});
}

export function allWidgetDetails(projectId) {
  return http.get(`${apiEndpoint}/whitelabelGetWidget/${projectId}`);
}

export function updateWidget(projectId, req) {
  return http.put(`${apiEndpoint}/whitelabelUpdateWidget/${projectId}`, req);
}
