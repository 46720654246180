import React from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableHeader,
} from "v2/common/table";

export function TableComponent({ data, headers }) {
  return (
    <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] p-5">
      <TableHead>
        <TableRow>
          {headers.map(({ label }) => (
            <TableHeader>{label}</TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item, index) => (
          <TableRow key={index}>
            {headers.map(({ key, getValue, render }) => {
              if (key === "actions") {
                return <TableCell>{render(item, index)}</TableCell>;
              }

              return (
                <TableCell>
                  {getValue ? getValue(item[key]) : item[key]}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
