import http from "./httpService";
const apiEndpoint =  "/voice";

export function placeCall(projectId, req) {
return http.post(apiEndpoint + "/" + "whitelabelPlaceOutboundCall" + "/" + projectId, req )}
  
  
export function registerTwilioNumber(projectId, req) {
    return http.post(apiEndpoint + "/" + "whitelabelRegisterNumber" + "/" + projectId, req )}  
  
  
  