import Joi from "joi-browser";

const phoneAgentFormSchema = {
  name: Joi.string().required().max(50).label("Name"),
  description: Joi.string().required().max(1000).label("Transfer Condition"),
  phoneNumber: Joi.number().required().label("Phone Number"),
  searchTerm: Joi.string().required().label("Keyword"),
  contactMedium: Joi.string().required(),
};

const whatsappAgentFormSchema = {
  name: Joi.string().required().max(50).label("Name"),
  contactMedium: Joi.string().required(),
  email: Joi.string().email().required().label("Email"),
  phoneNumber: Joi.string().required().label("Phone Number")
  // language: Joi.string().allow(null, "").label("Language"),
  // contactTemplate: Joi.string().allow(null, "").label("Contact Template"),
};

export { phoneAgentFormSchema, whatsappAgentFormSchema };
