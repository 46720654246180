import React, { Fragment, useState, useEffect, useMemo } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Joi from "joi-browser";
import Form from "../../components/Form";
import { getTemplates } from "v2/services/whatsappService";

export const useWhatsappTemplates = () => {
  const [messageTemplates, setMessageTemplates] = useState([]);

  const fetchTemplates = async (projectId) => {
    try {
      const response = await getTemplates(projectId);
      const templates = response.data;
      setMessageTemplates(
        templates.map((template) => ({
          name: template.name,
          language: template.language,
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  return { messageTemplates, fetchTemplates };
};

function Followup({
  followUpSettings,
  setFollowUpSettings,
  itemIndexForEdit,
  setItemIndexForEdit,
  messageTemplates,
  handleSave,
  open,
  setOpen,
  mode,
}) {
  const [inputField, setInputField] = useState({
    followUpMessage: "",
    followUpDelay: "",
    followUpTemplate: JSON.stringify({ name: "", language: "" }),
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage] = useState({});

  useEffect(() => {
    if (mode === "edit" && itemIndexForEdit !== null) {
      const { followUpMessage, followUpDelay, whatsAppFollowUp } =
        followUpSettings[itemIndexForEdit];
      setInputField({
        followUpMessage,
        followUpDelay,
        followUpTemplate: JSON.stringify({
          name: whatsAppFollowUp.templateName,
          language: whatsAppFollowUp.language,
        }),
      });
    }
  }, [mode, itemIndexForEdit, followUpSettings]);

  const template = useMemo(
    () => [
      {
        name: "followUpDelay",
        label: "Follow-up after (hours)",
        type: "text",
      },
      {
        name: "followUpMessage",
        label: "SMS Follow-up message",
        description: "Only applicable for SMS Campaign",
        type: "textArea",
        style: { height: "300px" },
      },
      {
        name: "followUpTemplate",
        label: "Whatsapp Follow-up template",
        description: "Select a template for Whatsapp Campaign",
        type: "select",
        options: messageTemplates.map((template, index) => ({
          id: index,
          label: template.name,
          value: JSON.stringify({
            name: template.name,
            language: template.language,
          }),
        })),
        value: JSON.parse(inputField.followUpTemplate).name,
      },
    ],
    [messageTemplates]
  );

  const schema = {
    followUpDelay: Joi.number()
      .required()
      .label("Follow-up after (hours)")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.code) {
            case "number.base":
              err.message = "Follow-up after (hours) should be a number field";
              break;
            case "any.required":
              err.message = "Follow-up after (hours) is required";
              break;
          }
        });
        return errors;
      }),
    followUpMessage: Joi.string().allow(null, "").max(480).label("message"),
    followUpTemplate: Joi.object({
      name: Joi.string().allow(null, ""),
      language: Joi.string().allow(null, ""),
    }).label("Follow Up template"),
  };

  const buttonInfo = {
    label: "Save All ",
    style: "entire-width",
    class:
      "w-full inline-flex items-center justify-center rounded-lg border text-base font-semibold px-4 py-2 text-white bg-zinc-900 focus:outline-none",
  };

  const handleSubmit = async (data) => {
    let updatedFollowUps = [];

    const { followUpDelay, followUpMessage, followUpTemplate } = data;
    const followUpTemplateObject = JSON.parse(followUpTemplate);
    const updatedData = {
      followUpDelay,
      followUpMessage,
      whatsAppFollowUp: {
        templateName: followUpTemplateObject.name,
        language: followUpTemplateObject.language,
      },
    };

    if (mode === "add") {
      const addedFollowUp = { ...updatedData, order: followUpSettings.length };
      updatedFollowUps = [...followUpSettings, addedFollowUp];
    }

    if (mode === "edit") {
      const editedFollowUp = { ...updatedData, order: itemIndexForEdit };

      updatedFollowUps = followUpSettings.toSpliced(
        itemIndexForEdit,
        1,
        editedFollowUp
      );

      setItemIndexForEdit(null);
    }

    setFollowUpSettings(updatedFollowUps);
    handleSave(updatedFollowUps);

    setOpen(false);
    setLoading(false);
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                              Configure your follow up Message
                            </Dialog.Title>
                            <div className="ml-3 h-7 flex items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                onClick={() => setOpen(false)}
                              >
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                          <Form
                            key={JSON.stringify(inputField)}
                            errorMessage={errorMessage}
                            inputField={inputField}
                            template={template}
                            schema={schema}
                            buttonInfo={buttonInfo}
                            onSubmit={handleSubmit}
                            loading={loading}
                            setLoading={setLoading}
                          />
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default Followup;
