import React, { useState } from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";


function AddNewExtractionField({
  setNewExtractions,
  handleSecondary,
}) {
  const [inputField, setInputField] = useState({
    parameterName: "",
    parameterDescription: "",
    parameterChoices: [],
    parameterType: "",
  });


  let [previewData, setPreviewData] = useState("");

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "parameterName",
      label: "Name of the extracted field",
      type: "text",
    },
    {
      name: "parameterType",
      label: "Choose type of field",
      type: "radio",
      options: [
        { id: 1, value: "boolean", label: "Yes/No Field" },
        { id: 2, value: "open", label: "Open answer" },
        { id: 3, value: "choice", label: "Choice field" },
      ],
    },

    {
      name: "parameterDescription",
      label: "Describe what the AI should extract.",
      type: "textArea",
      style: { height: "250px" },
    },
    previewData.parameterType === "choice" && {
      name: "parameterChoices",
      label: "Provide a list of options for the AI to select from",
      type: "inputFieldList",
    },
  ];

  console.log("previewData.parameterType");

  const buttonInfo = {
    buttons: "2",
    primaryLabel: "Add field",
    secondaryLabel: "Cancel",
  };

  const schema = {
    parameterName: Joi.string().required().max(50).label("Name"),
    parameterDescription: Joi.string().required().max(300).label("Description"),
    parameterType: Joi.string().required().max(300).label("Type of field"),
    parameterChoices: Joi.array().items(Joi.string()).required(),
  };

  const getPreviewDataInParent = (dataDuplicate) => {
    if (!dataDuplicate) {
      setPreviewData("");
    }
    setPreviewData(dataDuplicate);
  };

  const handleYes = async (req) => {
    setNewExtractions((extractions) => [...extractions, req]);
    
    handleSecondary();
    setLoading(false);
  };

  return (
    <Form
      key={JSON.stringify(inputField)}
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={buttonInfo}
      onSubmit={handleYes}
      loading={loading}
      setLoading={setLoading}
      handleSecondary={handleSecondary}
      getValue={getPreviewDataInParent}
    />
  );
}

export default AddNewExtractionField;
