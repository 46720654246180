import React, { useState, useEffect } from "react";
import { Button } from "../../../common/button";
import { PlusIcon, XMarkIcon } from "@heroicons/react/16/solid";

import { Dialog, DialogTitle, DialogBody } from "../../../common/dialog";
import AddNewExtractionField from "./AddNewExtraction";
import TableConfirmation from "./TableConfirmation";

export function AddNewFieldDialog({
  isOpen,
  onClose,
  setNewExtractions,
  onAddNewExtraction,
}) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <div class="text-lg flex flex-row justify-between">
          <div>Add extraction field</div>
          <div class="text-lg">
            <Button plain onClick={onClose}>
              <XMarkIcon />
            </Button>
          </div>
        </div>
      </DialogTitle>
      <DialogBody>
        <AddNewExtractionField
          handleSecondary={onClose}
          setNewExtractions={setNewExtractions}
          onAddNewExtraction={onAddNewExtraction}
        />
      </DialogBody>
    </Dialog>
  );
}

export function NewFields({
  projectId,
  extraction,
  newExtractions,
  setNewExtractions,
  onSubmit,
  setIsOpen,
}) {
  useEffect(() => {
    if (newExtractions.length === 0) {
      setIsOpen(true);
    }
  }, []);

  return (
    <div className="border rounded-lg">
      <TableConfirmation
        extraction={extraction}
        confirmExtraction={newExtractions}
        setConfirmExtraction={setNewExtractions}
        projectId={projectId}
        onAddAnotherField={() => setIsOpen(true)}
        refetchExtractions={onSubmit}
      />
    </div>
  );
}
