import React from "react";

import { useForm } from "../../components/Form/useForm";
import Form from "../../components/Form";

import { Button } from "../../../../../common/button";
import { Dialog, DialogTitle, DialogBody, DialogActions } from "../../../../../common/dialog";

import { inputParamSchema } from "../models/schema";
import { InputParamsTemplate } from "../models/template";
import { inputParam } from "../models/inputParam";
import { DialogTitleWithClose } from "../../components/Dialog/DialogTitleWithClose";


export function AddInputParam({ isOpen, onClose, addInputParam }) {
  const { data, validate, error, handleChange, setError } = useForm(inputParam, inputParamSchema);

  const onAdd = () => {
    const errors = validate(data, inputParamSchema);

    if (errors) {
      setError(errors);
      return;
    }

    addInputParam(data);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <DialogTitleWithClose title="Add Input param" onClose={onClose} />
      </DialogTitle>
      <DialogBody>
        <Form
          key={JSON.stringify(inputParam)}
          data={data}
          template={InputParamsTemplate}
          error={error}
          handleChange={handleChange}
        />
      </DialogBody>
      <DialogActions>
        <Button onClick={onClose} outline>Cancel</Button>
        <Button onClick={onAdd}>Add</Button>
      </DialogActions>
    </Dialog>
  );
}
