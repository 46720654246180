import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import { message } from "antd";

import { Dialog, DialogTitle, DialogBody } from "../../../common/dialog";
import { Button } from "../../../common/button";

import { PlusIcon, XMarkIcon } from "@heroicons/react/16/solid";

import * as customActionService from "../../../services/customActions";

function NewEndConditionDialog({ projectId, refetchActions }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button outline onClick={() => setOpen(true)}>
        <PlusIcon /> Add Condition
      </Button>
      <Dialog
        className=""
        open={open}
        onClose={() => setOpen(false)}
        size="2xl"
      >
        <DialogTitle>
          <div class="text-lg flex flex-row justify-between">
            <div>Add End Call Condition</div>
            <div class="text-lg">
              <Button plain onClick={() => setOpen(false)}>
                <XMarkIcon />
              </Button>
            </div>
          </div>
        </DialogTitle>
        <DialogBody>
          <NewEndCondition
            projectId={projectId}
            refetchActions={refetchActions}
            onCancel={() => setOpen(false)}
          />
        </DialogBody>
      </Dialog>
    </>
  );
}

function NewEndCondition({
  projectId,
  refetchActions,
  onCancel,
}) {
  const [inputField] = useState({
    actionName: "",
    description: "",
  });

  let [previewData, setPreviewData] = useState("");

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "actionName",
      label: "Name of the event",
      type: "text",
    },

    {
      name: "description",
      label: "Describe when do you want to end the call",
      type: "textArea",
      style: { height: "150px" },
    },
  ];

  const buttonInfo = {
    buttons: "2",
    primaryLabel: "Add Condition",
    secondaryLabel: "Cancel",
  };

  const schema = {
    actionName: Joi.string().required().max(50).label("Event name"),
    description: Joi.string().max(1500).label("Description"),
  };

  const handleYes = async (data) => {
    data.actionFunction = "CALLEND";
    try {
      const response = await customActionService.addSMSTrigger(
        projectId,
        data
      );

      if (response.status === 200) {
        setLoading(false);
        refetchActions();
        onCancel();
        message.success("Condition added");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
      setLoading(false);
    }
  };

  const getPreviewDataInParent = (dataDuplicate) => {
    if (!dataDuplicate) {
      setPreviewData("");
    }
    setPreviewData(dataDuplicate);
  };

  return (
    <Form
      key={JSON.stringify(inputField)}
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={buttonInfo}
      onSubmit={handleYes}
      loading={loading}
      setLoading={setLoading}
      getValue={getPreviewDataInParent}
      handleSecondary={onCancel}
    />
  );
}

export default NewEndConditionDialog;
