import React, { useState } from "react";

import { Button } from "../../../../../common/button";
import { TableComponent } from "../../components/TableComponent";

import { PlusCircleIcon } from "@heroicons/react/16/solid";

import { AddInputParam } from "./AddParam";
import { EditInputParam } from "./EditParam";

export function InputParams({
  inputParams,
  updateInputParam,
  addInputParam,
  deleteInputParam,
}) {
  const [isOpen, setIsOpen] = useState({
    state: null,
    item: null,
    index: null,
  });

  return (
    <>
      <div>
        <div>
          <p className="text-base/6 font-medium sm:text-sm/6 my-2">
            Input Params
          </p>
          {!inputParams?.length && (
            <Button
              plain
              onClick={() =>
                setIsOpen({
                  state: "ADD",
                })
              }
            >
              <PlusCircleIcon />
              <span className="text-blue-600">input param</span>
            </Button>
          )}
        </div>

        {inputParams?.length > 0 && (
          <div className="border-solid border-2 border-slate-100 rounded-lg">
            <TableComponent
              data={inputParams}
              headers={[
                { key: "paramName", label: "Name" },
                { key: "paramType", label: "Type" },
                { key: "paramLocation", label: "Location" },
              ]}
              onEdit={(item, index) => {
                setIsOpen({
                  state: "EDIT",
                  item,
                  index,
                });
              }}
              onDelete={(item, index) => deleteInputParam(item, index)}
            />
            <div className="flex justify-start px-4 pb-4 text-blue-600">
              <Button
                plain
                onClick={() =>
                  setIsOpen({
                    state: "ADD",
                  })
                }
              >
                <PlusCircleIcon color="text-blue-600" />{" "}
                <span className="text-blue-600">input param</span>
              </Button>
            </div>
          </div>
        )}
        {isOpen.state === "EDIT" && (
          <EditInputParam
            isOpen={isOpen}
            onClose={() => setIsOpen({ state: null })}
            updateInputParam={(data) => updateInputParam(data, isOpen.index)}
            param={isOpen.item}
          />
        )}
        {isOpen.state === "ADD" && (
          <AddInputParam
            isOpen={isOpen}
            onClose={() => setIsOpen({ state: null })}
            addInputParam={addInputParam}
          />
        )}
      </div>
    </>
  );
}
