import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "../../../../../common/dropdown";

export function ConversationMoreOptions(props) {
    const { onDeleteConversation, onRetryExtraction} = props;
  return (
    <Dropdown>
      <DropdownButton plain>
        <EllipsisHorizontalIcon className="size-5"/>
      </DropdownButton>
      <DropdownMenu anchor="bottom-end">
        <DropdownItem onClick={onDeleteConversation}>
            Delete conversation
        </DropdownItem>
        <DropdownItem onClick={onRetryExtraction}>
            Retry extraction
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
