import React, { useEffect, useState } from "react";
import { LinkIcon, PencilSquareIcon } from "@heroicons/react/20/solid";
import { useOutletContext } from "react-router-dom";
import * as KnowldegeService from "v2/services/knowledgebaseService";
import {
  ChevronRightIcon,
  DocumentTextIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";

import Paragraphs from "./Paragraphs";
import WebLinks from "./WebLinks";
import Files from "./Files";
import Spreadsheets from "./Spreadsheets";

const KNOWLEDGE_BASE = {
  PARAGRAPHS: "Paragraphs",
  WEB_LINKS: "Web Links",
  FILES: "Files",
  SPREADSHEET: "Spreadsheets",
};

const knowledgeBaseComponents = {
  [KNOWLEDGE_BASE.PARAGRAPHS]: (props) => <Paragraphs {...props} />,
  [KNOWLEDGE_BASE.WEB_LINKS]: (props) => <WebLinks {...props} />,
  [KNOWLEDGE_BASE.FILES]: (props) => <Files {...props} />,
  [KNOWLEDGE_BASE.SPREADSHEET]: (props) => <Spreadsheets {...props} />,
};

const KnowledgeBaseIcons = {
  [KNOWLEDGE_BASE.PARAGRAPHS]: (
    <PencilSquareIcon className="size-5 text-gray-500" />
  ),
  [KNOWLEDGE_BASE.WEB_LINKS]: <LinkIcon className="size-5 text-gray-500" />,
  [KNOWLEDGE_BASE.FILES]: <DocumentTextIcon className="size-5 text-gray-500" />,
  [KNOWLEDGE_BASE.SPREADSHEET]: (
    <TableCellsIcon className="size-5 text-gray-500" />
  ),
};

function KnowledgeBaseSection(props) {
  const { sectionName, onSectionClick, isActive, icon } = props;
  return (
    <div
      onClick={onSectionClick}
      className={`w-full h-16 flex items-center justify-between px-4 hover:bg-gray-300 hover:cursor-pointer ${
        isActive ? "bg-gray-200" : ""
      }`}
    >
      <div className="flex items-center gap-x-2">
        {icon && icon}
        <p className="font-semibold text-sm">{sectionName}</p>
      </div>
      <div className="flex justify-end">
        <ChevronRightIcon className="size-5 text-gray-400" />
      </div>
    </div>
  );
}

export function KnowledgeBase({}) {
  const [activeTab, setActiveTab] = useState(KNOWLEDGE_BASE.PARAGRAPHS);
  const [knowledge, setKnowledge] = useState({
    data: {
      paragraphs: [],
      corpus: [],
    },
    loading: true,
    exists: false,
  });
  const { projects, activeProject, projectId } = useOutletContext();
  const [dialogMode, setDialogMode] = useState({ isOpen: false, mode: null });

  const tabs = Object.values(KNOWLEDGE_BASE).map((tab) => ({
    name: tab,
    current: tab === activeTab,
    href: "#",
  }));

  const fetchKnowledge = async (projectId) => {
    try {
      const response = await KnowldegeService.getSpecificKB(projectId);
      if (response.data) {
        const data = response.data;

        setKnowledge({ data, loading: false, exists: true });
      } else {
        setKnowledge((prev) => ({ ...prev, loading: false, exists: false }));
      }
    } catch (ex) {
      setKnowledge((prev) => ({ ...prev, loading: false }));
      console.error(ex);
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchKnowledge(projectId);
    }
  }, [projectId]);

  return (
      <div className="flex w-full h-full">
        <div className="w-1/4 h-[calc(100vh-1rem)] border-r flex flex-col">
          <div className="flex justify-between px-4 pt-4 pb-4 border-b">
            <p className="font-bold text-lg">Knowledge Base</p>
          </div>
          {tabs.map((tab) => (
            <KnowledgeBaseSection
              sectionName={tab.name}
              isActive={tab.current}
              onSectionClick={() => setActiveTab(tab.name)}
              icon={KnowledgeBaseIcons[tab.name]}
            />
          ))}
        </div>
        <div className="w-3/4 h-100vh">
          {knowledgeBaseComponents[activeTab]({
            originalKBData: knowledge.data.paragraphs,
            KB: knowledge.data,
            projects,
            activeProject,
            setEditItem: () => {},
            kbLoading: knowledge.loading,
            kbExists: knowledge.exists,
            refetch: fetchKnowledge
          })}
        </div>
    </div>
  );
}
