const ParamsTemplate = [
  {
    name: "paramName",
    label: "Param Name",
    type: "text",
  },
  {
    name: "persistParam",
    label: "Persist Param",
    type: "toggle",
  },
];

const InputParamsTemplate = [
  ...ParamsTemplate,
  {
    name: "paramType",
    label: "Param Type",
    type: "select",
    options: [
      {id: 1, label: "static", value: "static"},
      {id: 2, label: "dynamic", value: "dynamic"}
    ]
  },
  {
    name: "paramValue",
    label: "Param Value",
    type: "text",
  },
  {
    name: "paramPrefix",
    label: "Param Prefix",
    type: "text",
  },
  {
    name: "paramLocation",
    label: "Param Location",
    type: "select",
    options: [
      { id: 1, value: "body", label: "body" },
      { id: 2, value: "header", label: "header" },
      { id: 3, value: "query", label: "query" },
    ],
  },
];

const ReturnParamsTemplate = [
  ...ParamsTemplate,
  {
    name: "useLocation",
    label: "Use Location",
    type: "select",
    options: [
      { id: 1, value: "API", label: "API" },
      { id: 2, value: "prompt", label: "prompt" },
    ],
  },
  {
    name: "returnType",
    label: "Return Type",
    type: "select",
    options: [
      {id: 1, value: "text", label: "text"},
      {id: 2, value: "object", label: "object"}
    ]
  },
];

export { InputParamsTemplate, ReturnParamsTemplate };
