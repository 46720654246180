import React, { useState, useEffect } from "react";
import Joi, { cloneWithShallow } from "joi-browser";
import Form from "../../components/Form";
import { Button } from "../../common/button";
import * as projectService from "../../services/projectService";
import { message } from "antd";

function WelcomeMessage({ projects, activeProject, settings, fetchSettings}) {
  const [inputField, setInputField] = useState({
    welcomeMessages: settings?.RAGSettings?.welcomeMessages || "",
  });

  useEffect(() => {
    if (settings) {
      setInputField((prevState) => {
        const newState = {
          welcomeMessages: settings?.RAGSettings?.welcomeMessages,
        };

        return newState;
      });
    }
  }, [settings, projects, activeProject]);

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "welcomeMessages",
      label: "Welcome Message",
      type: "textArea",
      style: { height: "40px" },
    },
  ];

  const buttonInfo = { label: "Save", style: "right-side" };

  const schema = {
    welcomeMessages: Joi.string().max(350).label("Welcome message"),
  };

  const handleCreate = async (data) => {
    try {
      const response = await projectService.updateProject(
        projects[activeProject]._id,
        { RAGSettings: data }
      );
      if (response.status === 200) {
        message.success("Changes saved");
        fetchSettings();
        // setReload(!reload)
      } else {
        message.error("Failed to save changes");
      }
    } catch (error) {
      message.error("An error occurred: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col">
      <Form
        key={JSON.stringify(inputField)}
        errorMessage={errorMessage}
        inputField={inputField}
        template={template}
        schema={schema}
        onSubmit={handleCreate}
        loading={loading}
        setLoading={setLoading}
        // getValue ={getInputValue}
        buttonInfo={buttonInfo}
        enableActionsOnChange={true}
        showRevert={true}
      />
    </div>
  );
}

export default WelcomeMessage;
