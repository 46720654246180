import React from "react";

import { useForm } from "../../components/Form/useForm";
import Form from "../../components/Form";

import { Dialog, DialogTitle, DialogActions, DialogBody } from "../../../../../common/dialog";
import { Button } from "../../../../../common/button";

import { returnParam } from "../models/returnParam";
import { returnParamSchema } from "../models/schema";
import { ReturnParamsTemplate } from "../models/template";
import { DialogTitleWithClose } from "../../components/Dialog/DialogTitleWithClose";

export function AddReturnParam({ isOpen, onClose, addReturnParam }) {
  const { data, validate, error, handleChange, setError } = useForm(
    returnParam,
    returnParamSchema
  );

  const onAdd = () => {
    const errors = validate(data, returnParamSchema);

    if (errors) {
      setError(errors);
      return;
    }

    addReturnParam(data);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <DialogTitleWithClose title="Add Return param" onClose={onClose} />
      </DialogTitle>
      <DialogBody>
        <Form
          key={JSON.stringify(returnParam)}
          data={data}
          template={ReturnParamsTemplate}
          error={error}
          handleChange={handleChange}
        />
      </DialogBody>
      <DialogActions>
        <Button onClick={onClose} outline>Cancel</Button>
        <Button onClick={onAdd}>Add</Button>
      </DialogActions>
    </Dialog>
  );
}
