import { useOutletContext } from "react-router-dom";
import { IntegrationShell } from "../common/Shell";
import PineConeComponent from "v2/HomePages/HomeModule/PineConeComponent";

export function PineConeIntegration() {
  const { projectId, settings, fetchSettings } = useOutletContext();

  return (
    <IntegrationShell>
      <PineConeComponent
        projectId={projectId}
        settings={settings}
        fetchSettings={fetchSettings}
      />
    </IntegrationShell>
  );
}
