import React from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableHeader,
} from "../../../../../common/table";
import { Button } from "../../../../../common/button";

import { PencilIcon, TrashIcon } from "@heroicons/react/16/solid";

export function TableComponent({
  data,
  headers,
  onEdit,
  onDelete,
  onRowClick,
}) {
  return (
    <Table className="p-5">
      <TableHead className="sticky top-0">
        <TableRow>
          {headers.map(({ label }) => (
            <TableHeader>{label}</TableHeader>
          ))}
          <TableHeader>Action</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.length > 0 &&
          data.map((item, index) => (
            <TableRow key={index} onClick={() => onRowClick(item)}>
              {headers.map(({ key, format }) => (
                <TableCell>{format ? format(item[key]) : item[key]}</TableCell>
              ))}
              <TableCell>
                <div className=" flex flex-row gap-x-2">
                  {onEdit && (
                    <Button
                      outline
                      onClick={(e) => {
                        e.stopPropagation();
                        onEdit(item, index);
                      }}
                    >
                      <PencilIcon />
                    </Button>
                  )}
                  <Button
                    outline
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete(item, index);
                    }}
                  >
                    <TrashIcon />
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
