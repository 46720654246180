import http from "./httpService";
const apiEndpoint = "/apiKey";


export function generateKey(projectId) {
    return http.post(apiEndpoint + "/whitelabelGenerateKey/" + projectId);
}

export function allKeys(projectId) {
    return http.get(apiEndpoint + "/whitelabelGetAllAPIKeys/" + projectId);
}

export function deleteKey(projectId,keyId) {
    return http.delete(apiEndpoint + "/whitelabelDeleteAPIKey/" + projectId + "/" + keyId);
}








