import { Button } from "v2/common/button";
import {
  Dialog,
  DialogActions,
  DialogDescription,
  DialogTitle,
} from "v2/common/dialog";
import { deleteContact } from "..";
import { message } from "antd";

export function DeleteContact(props) {
  const { fetchContacts, projectId, open, onClose, contact } = props;

  const onDelete = async () => {
    try {
      const response = await deleteContact({
        projectId,
        contactId: contact._id,
      });

      if (response.status === 200) {
        message.success("Contact deleted");
        fetchContacts(projectId);
        onClose();
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to delete contact");
    }
  };

  return (
    open && (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Delete Contact?</DialogTitle>
        <DialogDescription>
          Do you want the delete the contact {contact.phone}
        </DialogDescription>
        <DialogActions>
          <Button outline onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onDelete}>Yes, delete</Button>
        </DialogActions>
      </Dialog>
    )
  );
}
