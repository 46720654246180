import React, { useState, useCallback, useEffect } from "react";
import { Switch } from "../../../../common/switch";

import * as projectService from "../../../../services/projectService";
import { message } from "antd";

function useCustomAPIsSettings(projectId, settings, refetchSettings) {
  const [isCustomAPIsEnabled, setIsCustomAPIEnabled] = useState(false);

  useEffect(() => {
    setIsCustomAPIEnabled(settings?.voiceChatSettings?.customAPIsEnabled);
  }, [settings]);

  const toggleCustomAPIs = useCallback(async () => {
    setIsCustomAPIEnabled(!isCustomAPIsEnabled);

    const requestData = {
      customAPIsEnabled: !isCustomAPIsEnabled,
    };

    const response = await projectService.updateProject(projectId, {
      voiceChatSettings: requestData,
    });

    if (response.status === 200) {
      message.success("Changes saved");
      refetchSettings();
    }
  }, [isCustomAPIsEnabled]);

  return {
    isCustomAPIsEnabled,
    toggleCustomAPIs,
  };
}

export function EnableCustomAPIs({
  projectId,
  settings,
  refetchSettings,
}) {
  const { isCustomAPIsEnabled, toggleCustomAPIs } = useCustomAPIsSettings(
    projectId,
    settings,
    refetchSettings
  );

  return (
    <Switch
      color="emerald"
      id={`checkbox-${isCustomAPIsEnabled}`}
      checked={isCustomAPIsEnabled}
      onClick={toggleCustomAPIs}
    />
  );
}
