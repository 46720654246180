import React, { useEffect, useState } from "react";

import { TableComponent } from "../components/TableComponent";
import { Button } from "v2/common/button";
import { TrashIcon } from "@heroicons/react/24/outline";

import { deleteWebhook } from "v2/services/webhookService";
import { Dialog, DialogActions, DialogTitle } from "v2/common/dialog";
import { message } from "antd";

const getTime = (data) => {
  if (data === 0) return "Never";

  const epochTime = data;
  const date = new Date(epochTime);
  const dateString = date.toLocaleString(); // or use toUTCString() or toDateString() as needed
  return dateString;
};

function WebhookDelete(props) {
  const { webhook, projectId, fetchWebhooks } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClose = () => setOpen(false);
  const onDeleteClick = () => setOpen(true);

  async function handleDelete() {
    setLoading(true);
    try {
      const response = await deleteWebhook({
        projectId,
        webhookId: webhook._id,
      });

      if (response.status === 200) {
        setLoading(false);
        message.success("Webhook deleted");
        fetchWebhooks(projectId);
        onClose();
      }
    } catch (error) {
      setLoading(false);
      message.error("Webhook delete failed");
      console.error(error);
    }
  }

  return (
    <>
      <Button plain onClick={onDeleteClick}>
        <TrashIcon />
      </Button>
      {open && (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>Delete {webhook.webhookName}?</DialogTitle>
          <DialogActions>
            <Button outline onClick={onClose}>
              Cancel
            </Button>
            <Button loading={loading} onClick={handleDelete}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export function SavedWebhooks({ projectId, webhooks, fetchWebhooks }) {
  useEffect(() => {
    if (projectId) {
      fetchWebhooks(projectId);
    }
  }, [projectId]);

  const webhookColumns = [
    {
      key: "webhookName",
      label: "Name",
      getValue: (data) => data,
    },
    {
      key: "endpointUrl",
      label: "URL",
      getValue: (data) => data,
    },
    {
      key: "authRequired",
      label: "Authenticated",
      getValue: (data) => data.toString(),
    },
    {
      key: "createdAt",
      label: "Created at",
      getValue: (data) => getTime(data),
    },
    {
      key: "actions",
      render: (item, index) => (
        <WebhookDelete
          webhook={item}
          projectId={projectId}
          fetchWebhooks={fetchWebhooks}
        />
      ),
    },
  ];

  return <TableComponent data={webhooks} headers={webhookColumns} />;
}
