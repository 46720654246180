import Joi from "joi-browser";

const fields = {
  firstName: {
    template: {
      name: "firstName",
      label: "First Name",
      type: "text",
    },
    schema: Joi.string().allow(null, "").label("First Name"),
  },
  lastName: {
    template: {
      name: "lastName",
      label: "Last Name",
      type: "text",
    },
    schema: Joi.string().allow("").label("Last Name"),
  },
  email: {
    template: {
      name: "email",
      label: "Email",
      type: "email",
    },
    schema: Joi.string().allow("").email().label("email"),
  },
  phone: {
    template: {
      name: "phone",
      label: "Phone",
      type: "text",
      description: "Please prefix (+ country code)",
    },
    schema: Joi.string().required().label("Phone"),
  },
  description: {
    template: {
      name: "description",
      label: "Description",
      type: "text",
    },
    schema: Joi.string().allow("").label("Descripiton"),
  },
  tags: {
    template: {
      name: "tags",
      label: "Tags",
      type: "text",
      description: "",
    },
    schema: Joi.string().allow("").label("Tags"),
  },
  contactCompany: {
    template: {
      name: "contactCompany",
      label: "Company",
      type: "text",
    },
    schema: Joi.string().allow("").label("Company"),
  },
  url: {
    template: {
      name: "url",
      label: "Company URL",
      type: "text",
    },
    schema: Joi.string().allow("").label("Company URL"),
  },
  addressLine1: {
    template: {
      name: "addressLine1",
      label: "Address Line 1",
      type: "text",
    },
    schema: Joi.string().allow("").label("Address Line 1"),
  },
  addressLine2: {
    template: {
      name: "addressLine2",
      label: "Address Line 2",
      type: "text",
    },
    schema: Joi.string().allow("").label("Address Line 2"),
  },
  country: {
    template: {
      name: "country",
      label: "Country",
      type: "text",
    },
    schema: Joi.string().allow("").label("Country"),
  },
  state: {
    template: {
      name: "state",
      label: "State",
      type: "text",
    },
    schema: Joi.string().allow("").label("State"),
  },
  city: {
    template: {
      name: "city",
      label: "City",
      type: "text",
    },
    schema: Joi.string().allow("").label("City"),
  },
  postalCode: {
    template: {
      name: "postalCode",
      label: "Postal Code",
      type: "text",
    },
    schema: Joi.string().allow("").label("Postal Code"),
  },
  timezone: {
    template: {
      name: "timezone",
      label: "Time zone",
      type: "text",
    },
    schema: Joi.string().allow("").label("Time zone"),
  },
};

const fieldTemplate = Object.values(fields).map((field) => field.template);
const fieldSchema = Object.keys(fields).reduce(
  (schemaAcc, field) => ({ ...schemaAcc, [field]: fields[field].schema }),
  {}
);

export { fieldSchema, fieldTemplate };
