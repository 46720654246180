import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "v2/components/Form";
import { message } from "antd";
import * as humanAgentService from "v2/services/humanAgentService";
import {
  AdjustmentsHorizontalIcon,
  XMarkIcon,
} from "@heroicons/react/16/solid";
import { Button } from "v2/common/button";
import { Dialog, DialogTitle, DialogBody } from "v2/common/dialog";

function ConfigureAgentTransfer({
  projectId,
  settings,
  humanAgents,
  refetchHumanAgents,
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button outline onClick={() => setOpen(true)}>
        <AdjustmentsHorizontalIcon /> Configure
      </Button>
      <Dialog
        className="relative top-20"
        open={open}
        onClose={() => setOpen(false)}
        size="2xl"
      >
        <DialogTitle>
          <div class="text-lg flex flex-row justify-between">
            <div>Whatsapp Agent Settings</div>
            <div class="text-lg">
              <Button plain onClick={() => setOpen(false)}>
                <XMarkIcon />
              </Button>
            </div>
          </div>
        </DialogTitle>
        <DialogBody>
          <div className="">
            <TransferMessageForm
              projectId={projectId}
              settings={settings}
              humanagentsInfo={humanAgents}
              refetchHumanAgents={refetchHumanAgents}
              onCancel={() => setOpen(false)}
            />
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
}

function TransferMessageForm({
  projectId,
  humanagentsInfo,
  refetchHumanAgents,
  onCancel
}) {
  const [inputField, setInputField] = useState({
    whatsAppTransferTrigger: "",
    whatsAppTransferMessage: "",
  });

  useEffect(() => {
    setInputField({
      whatsAppTransferMessage: humanagentsInfo
        ? humanagentsInfo.whatsAppTransferSettings?.whatsAppTransferMessage
        : "",
      whatsAppTransferTrigger: humanagentsInfo
        ? humanagentsInfo.whatsAppTransferSettings?.whatsAppTransferTrigger
        : "",
    });
  }, [humanagentsInfo]);

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "whatsAppTransferTrigger",
      label: "Transfer Condition",
      description: "Describe when the conversation should be transferred",
      type: "textArea",
      style: { height: "130px" },
    },
    {
      name: "whatsAppTransferMessage",
      label: "Transfer Message",
      description: "The message AI sends before human transfer to the customer",
      type: "textArea",
      style: { height: "130px" },
    },
  ];

  const buttonInfo = {
    label: "Save",
    style: "entire-width",
  };

  const schema = {
    whatsAppTransferMessage: Joi.string()
      .allow(null, "")
      .max(250)
      .label("Transfer Message"),
    whatsAppTransferTrigger: Joi.string()
      .required()
      .label("Transfer Condition"),
  };

  const handleYes = async (data) => {
    try {
      const response = await humanAgentService.updateParentAgent(projectId, {
        whatsAppTransferSettings: data,
      });

      if (response.status === 200) {
        setLoading(false);
        refetchHumanAgents();
        onCancel();
        message.success("Transfer hours and message updated");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Failed to update settings");
      setLoading(false);
    }
  };

  return (
    <Form
      key={JSON.stringify(inputField)}
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={buttonInfo}
      onSubmit={handleYes}
      loading={loading}
      setLoading={setLoading}
    />
  );
}

export default ConfigureAgentTransfer;
