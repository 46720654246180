import { TableComponent } from "v2/HomePages/HomeModule/CustomAPIs/components/TableComponent";

import { useWhatsappTemplates } from "v2/HomePages/Integrations/Whatsapp/models/useWhatsappTemplates";

export function WhatsappTemplates(props) {
  const { projectId } = props;
  const { templates } = useWhatsappTemplates(projectId);

  const headers = [
    {
      label: "Name",
      key: "name",
    },
    { label: "Language", key: "language" },
    { label: "Status", key: "status" },
    { label: "Category", key: "category" },
  ];

  return (
    <div>
      <div></div>
      <div className="border rounded-lg">
        <TableComponent
          data={templates.data}
          headers={headers}
          onDelete={() => {}}
        />
      </div>
    </div>
  );
}
