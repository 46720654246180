import lock from "../../../../images/lock.svg";

export function ThreadRecording(props) {
    const { isLoadingRecording, callRecordingUrl, selectedItem, handleGetRecordings} = props;

    if (isLoadingRecording) {
    return (
      <div className="p-6 max-w-sm mx-auto rounded-lg border flex items-center">
        <div>
          <div className="text-xl font-medium text-black">
            Downloading audio...
          </div>
        </div>
      </div>
    );
  }

  if (callRecordingUrl !== "") {
    return (
      <div className="p-6 max-w-sm mx-auto rounded-lg border shadow-md flex items-center">
        <div>
          <div className="text-xl font-medium text-black">Call Recording</div>
          <audio controls className="mt-2">
            <source src={callRecordingUrl} type="audio/wav" />
            Your browser does not support the audio element.
          </audio>
        </div>
      </div>
    );
  }

  if (
    selectedItem.sourceOfConversation === "INBOUNDVOICE" ||
    selectedItem.sourceOfConversation === "OUTBOUNDVOICE"
  ) {
    return (
      <div
        onClick={handleGetRecordings}
        className="p-6 max-w-sm mx-auto border rounded-lg flex items-center cursor-pointer"
      >
        <div className="flex flex-row items-center">
          <img
            style={{ width: "30px" }}
            className="m-2"
            src={lock}
            alt="Lock Icon"
          />
          <span className="bg-white pr-2 text-sm text-gray-500">
            Download call recording securely
          </span>
        </div>
      </div>
    );
  }

  return null;
}
