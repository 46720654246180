import { languages } from "./whatsapp_languages";

const phoneAgentFormTemplate = [
  {
    name: "name",
    label: "Name",
    description: "Give a name to your agent",
    type: "text",
  },
  {
    name: "description",
    label: "Transfer Condition",
    description: "Describe when the call should be transferred",
    type: "textArea",
    style: { height: "150px" },
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    type: "text",
  },
  {
    name: "searchTerm",
    label: "Keyword",
    description:
      "Please enter a keyword for this agent. For example, if you have agents in different cities such as London, Paris, or Sydney, use the city name as the keyword. If the agent is associated with a specific department like Sales or Support, use the department name as the keyword.",
    type: "text",
  },
];

const generatewhatsappAgentFormTemplate = (templates) => [
  {
    name: "name",
    label: "Name",
    description: "Give a name to your agent",
    type: "text",
  },
  {
    name: "email",
    label: "Email",
    type: "email",
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    type: "text",
    description: "Please prefix with country code"
  }
  // {
  //   name: "language",
  //   label: "Language",
  //   type: "select",
  //   options: languages,
  // },
  // {
  //   name: "contactTemplate",
  //   label: "Contact Template",
  //   type: "select",
  //   options: templates?.map((template, index) => ({
  //     id: index,
  //     value: template.name,
  //     label: template.name,
  //   })),
  // },
  // {
  //   name: "searchTerm",
  //   label: "Keyword",
  //   description:
  //     "Please enter a keyword for this agent. For example, if you have agents in different cities such as London, Paris, or Sydney, use the city name as the keyword. If the agent is associated with a specific department like Sales or Support, use the department name as the keyword.",
  //   type: "text",
  // },
];

const generateAgentContactTemplateFieldTemplate = (templates) => {
  return {
    name: "contactTemplate",
    label: "Contact Template",
    type: "select",
    options: templates.map((template, index) => ({
      id: index,
      value: template,
      label: template,
    })),
  };
};

export {
  phoneAgentFormTemplate,
  generatewhatsappAgentFormTemplate,
  generateAgentContactTemplateFieldTemplate,
};
