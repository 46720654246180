import { useOutletContext } from "react-router-dom";
import ChromaComponent from "v2/HomePages/HomeModule/ChromaComponent";
import { IntegrationShell } from "../common/Shell";

export function ChromaDBIntegration() {
  const { projectId, settings, fetchSettings } = useOutletContext();

  return (
    <IntegrationShell>
      <ChromaComponent
        projectId={projectId}
        settings={settings}
        fetchSettings={fetchSettings}
      />
    </IntegrationShell>
  );
}
