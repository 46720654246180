import React, { useState } from "react";
import Joi from "joi-browser";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogBody,
  DialogDescription,
} from "../../../common/dialog";
import { Button } from "../../../common/button";
import { Field, ErrorMessage, Label } from "../../../common/fieldset";
import { Input } from "../../../common/input";

import * as projectService from "../../../services/projectService";

import { message } from "antd";

export function CreateNewWorkspace(props) {
  const { isOpen, onClose, projects, company, fetchProjects } = props;

  const [inputField, setInputField] = useState({
    "Workspace name": "",
  });

  const [error, setError] = useState({
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const validate = () => {
    const { error } = Joi.validate(inputField, {
      "Workspace name": Joi.string().min(3).max(45),
    });
    if (error) {
      setError({ message: error.details[0].message });
      return true;
    }
    setError({ message: "" });
    return false;
  };

  const handleSubmit = async () => {
    if (validate()) {
      return;
    }
    setLoading(true);
    message.loading("Creating your workspace");

    try {
      const response = await projectService.createProject(
        { title: inputField["Workspace name"] },
        company._id,
        "voice"
      );
      if (response.status === 200) {
        message.success("Workspace created");
        setLoading(false);
        localStorage.setItem("activeProject", projects.length.toString());
        fetchProjects();
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 402) {
        message.info(
          "Please upgrade your account to add more projects. Redirecting to home page"
        );
        setLoading(false);
        return;
      }
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false);
        message.error(ex.response && ex.response.data.msg);
        return;
      }
    }
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} size="lg">
      <DialogTitle className="text-md">Create a new workspace</DialogTitle>
      <DialogDescription>
        Examples: Inbound Sales Assistant, Matt's real estate project
      </DialogDescription>
      <DialogBody>
        <Field>
          <Label>Workspace Name</Label>
          <Input
            type="text"
            minLength={3}
            maxLength={45}
            value={inputField["Workspace name"]}
            onChange={(e) => {
              if (error.message) {
                setError({ message: "" });
              }
              setInputField({ "Workspace name": e.target.value });
            }}
          />
          <ErrorMessage>{error.message}</ErrorMessage>
        </Field>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={!!error.message || loading}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
