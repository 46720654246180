import React, { useEffect, useState } from "react";

import httpService from "../../services/httpService";
import { useOutletContext, useNavigate } from "react-router-dom";

import { Contact } from "./Contact";

function getContacts({ projectId }) {
  return httpService.get(`/contact/whitelabelGetContacts/${projectId}`);
}

function getContact({ projectId, contactId }) {
  return httpService.get(
    `/contact/whitelabelGetContact/${projectId}/${contactId}`
  );
}

function createContact({ projectId, payload }) {
  return httpService.post(
    `/contact/whitelabelCreateContact/${projectId}`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

function updateContact({ projectId, contactId, payload }) {
  return httpService.put(
    `/contact/whitelabelEditContact/${projectId}/${contactId}`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

function deleteContact({ projectId, contactId }) {
  return httpService.delete(
    `/contact/whitelabelDeleteContact/${projectId}/${contactId}`
  );
}

export { getContact, getContacts, createContact, updateContact, deleteContact };

export function Contacts(props) {
  const { projectId } = useOutletContext();

  const [contacts, setContacts] = useState([]);
  const navigate = useNavigate();

  const fetchContacts = async (projectId) => {
    try {
      const response = await getContacts({ projectId });
      const data = response.data;
      setContacts(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchContacts(projectId);
    }
  }, [projectId]);

  return (
    <Contact
      contacts={contacts}
      fetchContacts={fetchContacts}
      projectId={projectId}
    />
  );
}
