import React, { useState } from "react";

import { Button } from "../../../../../common/button";

import { TableComponent } from "../../components/TableComponent";

import { PlusCircleIcon } from "@heroicons/react/16/solid";

import { AddReturnParam } from "./AddParam";
import { EditReturnParam } from "./EditParam";

function ExistingReturnParams({ returnParams, onEdit, onDelete }) {
  return (
    <TableComponent
      data={returnParams}
      headers={[
        { key: "paramName", label: "Name" },
        { key: "returnType", label: "Returns" },
        { key: "useLocation", label: "Use location" },
      ]}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
}

export function ReturnParam({
  returnParams,
  updateReturnParam,
  addReturnParam,
  deleteReturnParam,
}) {
  const [isOpen, setIsOpen] = useState({
    mode: null,
    item: null,
  });

  const setEdit = (item) => setIsOpen({ mode: "EDIT", item });
  const setAdd = () => setIsOpen({ mode: "ADD" });

  const resetIsOpen = () => setIsOpen({ mode: null, item: null });

  return (
    <>
      <div>
        <div>
          <p className="text-base/6 font-medium sm:text-sm/6 my-2">
            Return Params
          </p>
          {!returnParams?.length && (
            <Button plain onClick={setAdd}>
              <PlusCircleIcon />
              <span className="text-blue-600">return param</span>
            </Button>
          )}
        </div>
        {returnParams?.length > 0 && (
          <div className="border-solid border-2 border-slate-100 rounded-lg">
            <ExistingReturnParams
              returnParams={returnParams}
              onEdit={setEdit}
              onDelete={(item, index) => deleteReturnParam(item, index)}
            />
            <div className="px-4 pb-4">
              <Button plain onClick={setAdd}>
                <PlusCircleIcon />
                <span className="text-blue-600">return param</span>
              </Button>
            </div>
          </div>
        )}
        {isOpen.mode === "EDIT" && (
          <EditReturnParam
            isOpen={isOpen}
            onClose={resetIsOpen}
            updateReturnParam={updateReturnParam}
            param={isOpen.item}
          />
        )}
        {isOpen.mode === "ADD" && (
          <AddReturnParam
            isOpen={isOpen}
            onClose={resetIsOpen}
            addReturnParam={addReturnParam}
          />
        )}
      </div>
    </>
  );
}
