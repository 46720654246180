import {
  ChatBubbleBottomCenterTextIcon,
  EnvelopeIcon,
  GlobeAltIcon,
  PhoneArrowDownLeftIcon,
  PhoneArrowUpRightIcon,
} from "@heroicons/react/24/outline";
import { CONVERSATION } from "../../models/Conversation";

import { FaWhatsapp } from "react-icons/fa";
import { Badge } from "v2/common/badge";
import authService from "v2/services/authService";

// TODO: conversation refresh or remove new badge, if a human agent has visited the conversation
// TODO: on notification click, make the conversation id as the selected one

export const Conversation = {
  [CONVERSATION.WHATSAPP]: (props) => <WhatsappItem {...props} />,
  [CONVERSATION.WEB_CALL]: (props) => <WebCallItem {...props} />,
  [CONVERSATION.EMBEDDED_CHAT]: (props) => <EmbeddedChatItem {...props} />,
  [CONVERSATION.INBOUND_VOICE]: (props) => <InboundVoiceItem {...props} />,
  [CONVERSATION.OUTBOUND_VOICE]: (props) => <OutboundVoiceItem {...props} />,
  [CONVERSATION.SMS]: (props) => <SmsItem {...props} />,
  [CONVERSATION.API]: () => null
};

export const ConversationSourceIcon = {
  [CONVERSATION.WHATSAPP]: <FaWhatsapp />,
  [CONVERSATION.WEB_CALL]: <GlobeAltIcon className="size-5" />,
  [CONVERSATION.EMBEDDED_CHAT]: (
    <ChatBubbleBottomCenterTextIcon className="size-5" />
  ),
  [CONVERSATION.INBOUND_VOICE]: <PhoneArrowUpRightIcon className="size-5" />,
  [CONVERSATION.OUTBOUND_VOICE]: <PhoneArrowDownLeftIcon className="size-5" />,
  [CONVERSATION.SMS]: <EnvelopeIcon className="size-5" />,
};

export function SourceIcon({ children }) {
  return (
    <div className="min-h-10 min-w-10 bg-zinc-300 rounded-lg flex justify-center items-center">
      {children}
    </div>
  );
}

function WebCallItem(props) {
  const { item } = props;

  return (
    <ConversationItem
      {...props}
      title={item.userIdentifier || item.sourceOfConversation.toLowerCase()}
      description={item.attributes?.callDuration}
    />
  );
}

function EmbeddedChatItem(props) {
  return <ConversationItem {...props} />;
}

function InboundVoiceItem(props) {
  const { item } = props;
  return (
    <ConversationItem
      {...props}
      title={item.userIdentifier}
      description={item.attributes?.callDuration}
    />
  );
}
function OutboundVoiceItem(props) {
  const { item } = props;
  return (
    <ConversationItem
      {...props}
      title={item.userIdentifier}
      description={item.attributes?.callDuration}
    />
  );
}
function SmsItem(props) {
  const { item } = props;
  return <ConversationItem title={item.userIdentifier} {...props} />;
}

function WhatsappItem(props) {
  const { item } = props;
  const user = authService.getCurrentUser();

  const humanAgentAssignmentSettings = item?.humanAgentAssignmentSettings;
  let unread = false;

  if (
    humanAgentAssignmentSettings &&
    humanAgentAssignmentSettings?.humanAgentAssigned &&
    user.email === humanAgentAssignmentSettings?.humanAgentAssigned.email
  ) {
    const { messages } = item;
    const lastMessage = messages[messages.length - 1];

    if (
      item.status === 0 &&
      (!lastMessage.aTimestamp ||
        lastMessage.aTimestamp <
          humanAgentAssignmentSettings?.humanAgentRequestedAt)
    ) {
      unread = true;
    }
  }


  return (
    <ConversationItem
      {...props}
      title={item.userIdentifier}
      description={item?.messages[item.messages.length - 1]?.a || item?.messages[item.messages.length -1]?.q }
      icon={<FaWhatsapp />}
      unread={unread}
    />
  );
}

export function ConversationItem(props) {
  const {
    item,
    isSelected,
    title,
    description,
    updateSelectedConversation,
    unread,
  } = props;

  return (
    <div
      id={item._id}
      onClick={() => updateSelectedConversation(item)}
      className={`flex items-center p-4 overflow-hidden  transition duration-150 ease-in-out cursor-pointer ${
        isSelected ? "bg-gray-200" : "hover:bg-gray-100"
      }`}
    >
      <SourceIcon>
        {ConversationSourceIcon[item.sourceOfConversation]}
      </SourceIcon>
      <div className="ml-4">
        <p className="text-md font-normal text-gray-700">
          {title || item.sourceOfConversation}{" "}
        </p>
        <div className="grid grid-cols-[auto_1fr] gap-x-2">
          <p className="shrink text-sm text-gray-500 truncate mr-2">
            {description || ""}
          </p>
          {unread && (
            <div>
              <Badge color="green">new</Badge>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function ConversationItemLoading(props) {
  return (
    <div className="flex animate-pulse items-center p-4 overflow-hidden  transition duration-150 ease-in-out cursor-pointer w-full">
      <div className="min-h-10 min-w-10 bg-zinc-300 rounded-lg flex justify-center items-center m-1"></div>
      <div className="ml-4 w-full flex flex-col">
        <div className="bg-zinc-300 h-2"></div>
        <div className="grid grid-cols gap-x-2">
          <div className="shrink text-sm text-gray-500 truncate mr-2 bg-zinc-300"></div>
        </div>
      </div>
    </div>
  );
}
