import http from "./httpService";
const apiEndpoint = "/humanAgent";

export function getAllAgents(projectId) {
    return http.get(apiEndpoint + "/whitelabelGetAllHumanAgents/" + projectId);
  }

  export function addHumanAgent(projectId, data) {
    return http.post(apiEndpoint + "/whitelabelCreateHumanAgent/" + projectId, data);
  }

  export function updateHumanAgent(projectId, id, req) {
    return http.put(apiEndpoint + "/whitelabelUpdateHumanAgent/" + projectId + "/" + id, req);
  }

  export function deleteHumanAgent(projectId, id) {
    return http.delete(apiEndpoint + "/whitelabelDeleteHumanAgent/" + projectId + "/" + id);
  }


  export function updateParentAgent(projectId, req) {
    return http.put(apiEndpoint + "/whitelabelUpdateHumanAgentDoc/" + projectId, req);
  }


  

  
