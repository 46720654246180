import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as voiceEmbedService from "../../services/voiceEmbed";
import { message } from "antd";
import { Subheading } from "../../common/heading";
import CopyIcon from "../../images/copy.svg";
import { useOutletContext, useNavigate } from "react-router-dom";

function VoiceEmbed({}) {
  const { projectId } = useOutletContext();
  const navigate = useNavigate();
  const [formFieldforButton, setFormFieldforButton] = useState({
    buttonName: "Talk to AI",
    buttonColor: "#5567e7",
    fontColor: "#ffffff",
    // whitelistDomain: "",
    paddingLR: "8px",
    paddingTB: "8px",
    fontSize: "16px",
    width: "auto",
    interruptionThreshold: -40,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await voiceEmbedService.getSettings(projectId);
        const newState = {
          buttonColor: data.buttonColor ? data.buttonColor : "#5567e7",
          buttonName: data.buttonName,
          fontColor: data.fontColor ? data.fontColor : "#ffffff",
          // whitelistDomain: data.whitelistDomain,
          paddingLR: data.paddingLR ? data.paddingLR : "8px",
          paddingTB: data.paddingTB ? data.paddingTB : "8px",
          fontSize: data.fontSize ? data.fontSize : "14px",
          width: data.width ? data.width : "auto",
          interruptionThreshold: data.interruptionThreshold
            ? data.interruptionThreshold
            : -40,
        };

        setFormFieldforButton(newState); // Ensure correct function name is used
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, [projectId]);

  const [loading, setLoading] = useState(false);

  const [errorMessageForButton] = useState({});

  const templateForButtonStyle = [
    // {
    //     name: "whitelistDomain",
    //     description:"Add the domain where you will host the widget to securely make voice calls on the website",
    //     label: "",
    //     type: "text",
    //     style: "",
    //   },
    {
      name: "buttonName",
      label: "Button Name",
      type: "text",
      style: "",
    },
    {
      name: "buttonColor",
      label: "Button Color",
      type: "color",
      style: "",
    },
    {
      name: "fontColor",
      label: "Font Color",
      type: "color",
      style: "",
    },
    {
      name: "width",
      description:
        "The default value is 'auto.' The value should always end in 'px'",
      label: "Width",
      type: "text",
      style: "",
    },
    {
      name: "paddingLR",
      description:
        "The default value is '8px' The value should always end in 'px'",
      label: "Padding  Left / Right",
      type: "text",
      style: "",
    },
    {
      name: "paddingTB",
      description:
        "The default value is '8px' The value should always end in 'px'",
      label: "Padding  Top / Bottom",
      type: "text",
      style: "",
    },
    {
      name: "fontSize",
      description:
        "The default value is '14px' The value should always end in 'px'",
      label: "Font Size",
      type: "text",
      style: "",
    },
    {
      name: "interruptionThreshold",
      label: "Interruption Threshold",
      description:
        "A setting of 50 indicates no interruptions, while -50 allows for frequent interruptions. Default is -40",
      type: "slider",
      min: -50,
      max: 50,
      defaultValue: -40,
    },
  ];

  const buttonInfoForButton = {
    label: "Save configuration",
    style: "entire-width",
  };

  const schemaForButton = {
    buttonName: Joi.string().allow(null, "").max(5000).label("Button Name"),
    buttonColor: Joi.string().max(5000).required().label("Button Color"),
    fontColor: Joi.required().label("Font Color"),
    // whitelistDomain: Joi.string().max(5000).required().label("WhiteList Doamain"),
    paddingLR: Joi.string().required().label("Padding Left Right"),
    paddingTB: Joi.string().required().label("Padding Top Bottom"),
    fontSize: Joi.string().required().label("fontSize"),
    width: Joi.string().required().label("Width"),
    interruptionThreshold: Joi.number().min(-50).max(50).required(),
  };

  const handleButton = async (data) => {
    setLoading(true);
    try {
      const response = await voiceEmbedService.updateSettings(projectId, data);
      if (response.status === 200) {
        message.success("Changes saved");
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false);
        message.error(ex.response.data.msg);
      } else {
        setLoading(false);
        console.error("An unexpected error occurred:", ex);
      }
    }
  };

  const embedCode = `<iframe style="width:100vw; background: transparent;border: none; outline: none;" allow="microphone" src="https://www.superdashhq.com/voiceembed?projectID=${[
    projectId,
  ]}"></iframe>`;

  const handleCopy = () => {
    navigator.clipboard
      .writeText(embedCode)
      .then(() => {
        message.success("Embed code copied to clipboard!");
      })
      .catch((err) => {
        message.error("Failed to copy the embed code");
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div className="px-8 py-6 ">
      <div>
        <div className="flex flex-col gap-y-2">
          <p className="font-bold text-lg">Voice Embed</p>
          <p className="font-medium text-sm text-gray-500">
            Configure and embed voice agent to your application
          </p>
        </div>
      </div>
      <div className="w-full mx-auto mt-8 h-[calc(100vh-150px)] overflow-scroll">
        <div className="w-3/5 mx-auto border rounded-lg">
          <div className="flex justify-between items-center p-8">
            <span>Voice Embed Configuration</span>
            <div
              onClick={handleCopy}
              className="flex items-center cursor-pointer"
            >
              <img src={CopyIcon} alt="Copy Icon" className="w-6 h-6 mr-2" />
              <Subheading>Copy embed code</Subheading>
            </div>
          </div>
          <div className="p-8">
            <Form
              key={JSON.stringify(formFieldforButton)}
              errorMessage={errorMessageForButton}
              inputField={formFieldforButton}
              template={templateForButtonStyle}
              schema={schemaForButton}
              buttonInfo={buttonInfoForButton}
              onSubmit={handleButton}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoiceEmbed;
