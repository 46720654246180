import React, { useState, useEffect } from "react";
import { Checkbox, CheckboxField, CheckboxGroup } from "../../common/checkbox";
import { Description, Label } from "../../common//fieldset";
import { Button } from "../../common/button";
import * as projectService from "../../services/projectService";
import { message } from "antd";
import { useOutletContext, useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

function ZohoCRM() {
  const { projectId, settings, fetchSettings } = useOutletContext();
  const navigate = useNavigate();

  const [crmSearchEnabled, setCRMSearchEnabled] = useState(
    settings?.RAGSettings?.crmSearchEnabled &&
      settings?.RAGSettings?.defaultCRM === "ZohoCRM"
  );

  const handleSave = async () => {
    const data = {
      crmSearchEnabled: crmSearchEnabled,
    };
    const response = await projectService.updateProject(projectId, {
      RAGSettings: data,
    });
    if (response.status === 200) {
      message.success("Changes saved");
      fetchSettings(projectId);
    }
  };

  const handleZohoContactsSearch = () => {
    setCRMSearchEnabled(!crmSearchEnabled);
    message.info("Please click save to persist this configuration");
  };

  return (
        <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
          <div className="flex flex-col gap-y-2">
            <p className="font-bold text-lg">Zoho Contacts</p>
            <p className="font-medium text-sm text-gray-500">
            </p>
          </div>
          <div className="w-full mx-auto rounded-lg border my-10">
            <div className="p-5">
              <CheckboxGroup>
                <CheckboxField>
                  <Checkbox
                    name="zoho_crm_search"
                    onChange={handleZohoContactsSearch}
                    value={crmSearchEnabled}
                    checked={crmSearchEnabled}
                  />
                  <Label className="text-medium">Zoho contacts search</Label>
                  <Description>
                    Turn on this feature to automatically use the first name of
                    individuals from your Zoho CRM contacts on an inbound or
                    outbound call.
                  </Description>
                </CheckboxField>
              </CheckboxGroup>
            </div>
            <div className="px-4 py-4 sm:px-6 flex justify-end">
              <Button onClick={handleSave}>Save</Button>
            </div>
          </div>
        </div>
  );
}

export default ZohoCRM;
