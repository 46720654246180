import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useHistory } from "react-router";
// import { Redirect } from "react-router-dom";
import { message } from "antd";
import Joi from "joi-browser";
import Form from "../components/Form";
import * as customerService from "../services/customerService";
import auth from "../services/authService";
import { Link, Navigate } from "react-router-dom";

// import "../scss/onboarding.scss";

function SignUp() {

  const navigate = useNavigate();

  const [inputField] = useState({
    firstName:"",
    lastName:"",
    email: "",
    password: "",
  });



  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  // let auth = useAuth();
  //   const history = useHistory();

  const template = [
    {
      name: "firstName",
      label: "First name",
      type: "text",
    },
    {
      name: "lastName",
      label: "Last name",
      type: "text",
    },
    {
      name: "email",
      label: "Email",
      type: "email",
    },
    {
      name: "password",
      label: "Password",
      type: "password",
    },
  ];

  const buttonInfo = {

    label: "Get started ",
    style: "entire-width",
    class:
      "w-full inline-flex items-center justify-center rounded-lg border text-base font-semibold px-4 py-2 text-white bg-zinc-900 focus:outline-none",
  };

  const schema = {
    firstName:Joi.string().max(25).required().label("firstName"),
    lastName:Joi.string().max(25).required().label("lastName"),
    email: Joi.string().email().required().label("email"),
    password: Joi.string().min(8).required().label("password"),
  };



  const handleSubmit = async (user) => {
    try {
      const response = await customerService.signUpPortal(user);
      if (response.status === 200) {
        console.log("token", response.data.token)
        auth.loginWithJwt(response.data.token);
         navigate("/");
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setLoading(false);
        message.error(ex.response.data.msg);
      }
    }
  };
  
  return auth.getCurrentUser() ? (
    <Navigate to="/" />
  ) : (
    <div className="bg-slate-50  flex items-center justify-center h-screen w-screen ">
      <div className="relative w-[500px] rounded-xl bg-white shadow-[0px_0px_0px_1px_rgba(9,9,11,0.07),0px_2px_2px_0px_rgba(9,9,11,0.05)] dark:bg-zinc-900 dark:shadow-[0px_0px_0px_1px_rgba(255,255,255,0.1)] p-20">
        <div className="space-y-8">
          <Form
            formTitle={"Sign up"}
            errorMessage={errorMessage}
            inputField={inputField}
            template={template}
            schema={schema}
            buttonInfo={buttonInfo}
            onSubmit={handleSubmit}
            loading={loading}
            setLoading={setLoading}
          />
          <p className="text-base text-zinc-500 sm:text-sm dark:text-zinc-400">
            Already have an account?{" "}
            <a
              href="#"
              className="font-semibold text-zinc-950 hover:text-zinc-700 dark:text-white dark:hover:text-zinc-300"
              onClick={(e) => {
                e.preventDefault(); // Prevent the default anchor link behavior
                navigate('/Login'); // Navigate to the Signup page
              }}
            >
              Log In
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
