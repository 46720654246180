import { forwardRef, useImperativeHandle, useRef } from "react";

export const SearchConversation = forwardRef((props, ref) => {
  const { handleSearch } = props;
  const inputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    reset: () => {
      inputRef.current.value = "";
    }
  }), [ref]);

  return (
    <input
      ref={inputRef}
      type="search"
      onChange={handleSearch}
      placeholder="Search by Call ID or Phone Number"
      className="w-full rounded border border-gray-300 px-4 py-2 text-sm text-gray-600 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
    />
  );
});
