export const IndividualAPITemplate = [
    {
      name: "apiName",
      label: "Name",
      type: "text",
    },
    {
      name: "apiTriggerPrompt",
      label: "Trigger Prompt",
      type: "textArea",
      style: { height: "250px" },
    },
    {
      name: "triggerEvent",
      label: "Trigger Event",
      type: "select",
      options: [
        { id: 1, value: "callStart", label: "Start of the call" },
        { id: 2, value: "duringCall", label: "During the call" },
        { id: 3, value: "callEnd", label: "End of the call" },
      ],
    },
    {
      name: "callOnce",
      label: "Call Once",
      type: "toggle",
    },
    {
      name: "apiMethod",
      label: "Method",
      type: "select",
      options: [
        { id: 1, value: "POST", label: "POST" },
        { id: 2, value: "GET", label: "GET" },
      ],
    },
    {
      name: "apiURL",
      label: "URL",
      type: "text",
    },
    {
      name: "backchannelMessage",
      label: "Back channel message",
      type: "textArea",
      style: { height: "100px" },
    },
    {
      name: "successResponse",
      label: "Success Response",
      type: "textArea",
      style: { height: "100px" },
    },
    {
      name: "failureResponse",
      label: "Failure Response",
      type: "textArea",
      style: { height: "100px" },
    },
  ];