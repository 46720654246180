import { useState } from "react";

import { Button } from "v2/common/button";
import {
  Dialog,
  DialogActions,
  DialogDescription,
  DialogTitle,
} from "v2/common/dialog";
import { message } from "antd";
import { removeHumanAgent } from "v2/services/conversationService";

export function ResolveConversation(props) {
  const {
    projectId,
    conversationId,
    fetchConversations,
    resetSelectedConversation,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const resolveConversation = async (projectId, conversationId) => {
    try {
      const response = await removeHumanAgent(projectId, conversationId);

      if (response.status === 200) {
        fetchConversations(1, 50);
        resetSelectedConversation();
        message.success("Resolved");
        setIsOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button outline onClick={() => setIsOpen(true)}>
        Resolve Conversation
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Resolve Conversation</DialogTitle>
        <DialogDescription>
          By resolving, you're reassigning the conversation back to AI. Do you
          want to resolve?
        </DialogDescription>
        <DialogActions>
          <Button outline onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => resolveConversation(projectId, conversationId)}
          >
            Yes, Resolve
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
