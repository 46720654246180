import React from "react";
import { Webhooks } from "../../HomeModule/WebHooks/index";
import { useOutletContext } from "react-router-dom";


export function WebHooks(props) {
    const {  projectId } = useOutletContext();

  return (
    
    <Webhooks projectId={projectId}/>
  );
}
