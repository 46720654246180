import { useState, useEffect } from "react";

import { useThirdPartySettings } from "./useThirdParyData";

import { message } from "antd";

export function useShowOriginalCallerId(projectId) {
  const [toggleOriginalCallerId, setToggleOriginalCallerId] = useState(false);

  const { fetchThirdPartySettings, updateThirdPartySettings } =
    useThirdPartySettings(projectId);

  useEffect(() => {
    fetchThirdPartySettings(
      (data) => {
        const { twilioSettings } = data.telephonySettings;
        setToggleOriginalCallerId(twilioSettings?.showOriginalCallerId);
      },
      (error) => console.error(error)
    );
  }, []);

  const handleToggle = () => {
    setToggleOriginalCallerId((prevState) => !prevState);
  };

  const handleSave = () => {
    updateThirdPartySettings(
      {
        telephonySettings: {
          twilioSettings: {
            showOriginalCallerId: toggleOriginalCallerId,
          },
        },
      },
      () => {
        message.success("Caller ID updated");
      },
      () => {}
    );
  };

  return { toggleOriginalCallerId, handleToggle, handleSave };
}
