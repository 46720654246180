import React, { useState } from "react";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as projectService from "../../services/projectService";
import { message } from "antd";

import PineconeIcon from "v2/images/pinecone.png";

function PineConeComponent({ projectId, settings, fetchSettings }) {
  const [pineConeFields] = useState({
    pineconeIndex: settings?.vectorDbSettings?.pineconeSettings.pineconeIndex,
    pineconeEnvironment:
      settings?.vectorDbSettings?.pineconeSettings.pineconeEnvironment,
    pineconeKey: settings?.vectorDbSettings?.pineconeSettings.pineconeKey,
  });

  const [loading, setLoading] = useState(false);

  const [errorMessageZohoCRM] = useState({});

  const templateZohoCRM = [
    {
      name: "pineconeIndex",
      label: "Pinecone Index",
      type: "text",
      style: "",
    },
    {
      name: "pineconeEnvironment",
      label: "Pinecone Environment",
      type: "text",
      style: "",
    },
    {
      name: "pineconeKey",
      label: "Pinecone Key",
      type: "password",
      style: "",
    },
  ];

  const buttonInfo = {
    label: "Connect Pinecone",
    style: "entire-width",
  };

  const schemaforPinecone = {
    pineconeIndex: Joi.string().max(5000).required().label("Pinecone Index"),
    pineconeEnvironment: Joi.string()
      .max(5000)
      .required()
      .label("Pinecone Environment"),
    pineconeKey: Joi.required().label("Pinecone Key"),
  };

  const handlePineConnection = async (data) => {
    setLoading(true);

    let newData = {
      pineconeSettings: {
        pineconeIndex: data.pineconeIndex,
        pineconeEnvironment: data.pineconeEnvironment,
        pineconeKey: data.pineconeEnvironment,
      },
    };

    try {
      const response = await projectService.updateProject(projectId, {
        vectorDbSettings: newData,
      });
      if (response.status === 200) {
        message.success("Changes saved");
        setLoading(false);
        fetchSettings(projectId);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false); // Ensure setLoading is defined and used correctly
        message.error(ex.response.data.msg);
      } else {
        setLoading(false);
        console.error("An unexpected error occurred:", ex);
      }
    }
  };

  return (
    <div>
      <div className="p-8 rounded-lg border">
        <div className="flex gap-x-2 mb-4">
          <img src={PineconeIcon} width={24} />
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Connect Pinecone
          </h3>
        </div>

        <Form
          errorMessage={errorMessageZohoCRM}
          inputField={pineConeFields}
          template={templateZohoCRM}
          schema={schemaforPinecone}
          buttonInfo={buttonInfo}
          onSubmit={handlePineConnection}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
    </div>
  );
}

export default PineConeComponent;
