import Joi from "joi-browser";

import { inputParamSchema, returnParamSchema } from "../../Params/models/schema";

export const individualAPISchema = {
    apiName: Joi.string().required().max(50).label("Name"),
    apiTriggerPrompt: Joi.string().required().max(25000).label("Trigger Prompt"),
    apiMethod: Joi.string().required().label("Method"),
    triggerEvent: Joi.string().required().label("Trigger Event"),
    callOnce: Joi.boolean().allow(null, "false").label("Call Once"),
    apiURL: Joi.string().uri().required().label("URL"),
    backchannelMessage: Joi.string().optional().allow(null, "")
      .max(500)
      .label("Back channel message"),
    successResponse: Joi.string().optional().allow(null, "").max(300).label("Success Response"),
    failureResponse: Joi.string().optional().allow(null, "").max(300).label("Failure Response"),
    inputParams: Joi.array().items(inputParamSchema).label("Input Params"),
    returnParams: Joi.array().items(returnParamSchema).label("Return Params")
  };