import { useCallback, useState } from "react";

export function useSelectedConversation() {
    const [selectedConversation, setSelectedConversation] = useState({});

    const updateSelectedConversation = useCallback((conversation) => setSelectedConversation(conversation));

    return {
        selectedConversation,
        updateSelectedConversation
    }
}