import React, { useState, useEffect } from "react";
import { Button } from "v2/common/button";
import { PlusIcon, XMarkIcon } from "@heroicons/react/16/solid";
import { Dialog, DialogBody, DialogTitle } from "v2/common/dialog";
import { AgentForm, editAgentButtons } from "../../../common/Agent_form";
import { phoneAgentFormSchema } from "../../../models/schema";
import { phoneAgentFormTemplate } from "../../../models/template";
import { useUpdateHumanAgent } from "../../../models/useUpdateHumanAgent";
import { getPhoneAgent } from "../../../models/get_agents";
import { message } from "antd";

export function EditAgent({ agent,isOpen, onClose, projectId, refetchAgents }) {
    const { loading,setLoading, handleUpdateHumanAgent} = useUpdateHumanAgent();

    function handleSubmit(data) {
        const onSuccess = () => {
            refetchAgents();
            message.success("Agent Updated");
            onClose();
        }

        const onError = () => {
            message.error("Failed to update agent");
            onClose();
        }

        handleUpdateHumanAgent({projectId, agentId: agent._id, data, onSuccess, onError});
    }

  return (
      <Dialog
        className=""
        open={isOpen}
        onClose={onClose}
        size="2xl"
      >
        <DialogTitle>
          <div class="text-lg flex flex-row justify-between">
            <div>Edit Agent</div>
            <div class="text-lg">
              <Button plain onClick={onClose}>
                <XMarkIcon />
              </Button>
            </div>
          </div>
        </DialogTitle>
        <DialogBody>
          <AgentForm
            agent={getPhoneAgent(agent)}
            loading={loading}
            setLoading={setLoading}
            projectId={projectId}
            refetchAgents={refetchAgents}
            onCancel={onClose}
            onSubmit={handleSubmit}
            buttons={editAgentButtons}
            agentFormSchema={phoneAgentFormSchema}
            agentFormTemplate={phoneAgentFormTemplate}
          />
        </DialogBody>
      </Dialog>
  );
}
