function generateDownload(downloadName, url) {
  const anchor = document.createElement("a");

  anchor.href = url;
  anchor.target = "_blank";
  anchor.download = downloadName;
  anchor.click();
}

export { generateDownload };
