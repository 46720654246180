import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import { message } from "antd";
import * as customActionService from "../../../services/customActions";

function AddNewTrigger({
  tabState,
  projects,
  activeProject,
  settings,
  setTabState,
  onCancel,
  reload,
  setReload,
}) {
  const [inputField, setInputField] = useState({
    actionName: "",
    phoneNumber: "",
    description: "",
    contentText: "",
  });

  const [humanagents, setHumanAgents] = useState([]);
  const [forWhatsapp, setForWhatsapp] = useState(false);
  let [previewData, setPreviewData] = useState("");

  // const [reload, setReload] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "actionName",
      label: "Name of the event",
      type: "text",
    },
    {
      name: "phoneNumber",
      label: "To number",
      type: "text",
      description:
        "Leave it blank if you want to automatically pick the number from the call.",
    },
    {
      name: "description",
      label: "Describe when do you want to send the SMS",
      type: "textArea",
      style: { height: "150px" },
    },
    {
      name: "contentText",
      label: "SMS content",
      type: "textArea",
      style: { height: "100px" },
    },
  ];

  const addTriggerButtonInfo = {
    buttons: "2",
    primaryLabel: "Add Trigger",
    secondaryLabel: "Cancel",
  };

  const schema = {
    actionName: Joi.string().required().max(50).label("Event name"),
    phoneNumber: Joi.string()
      .allow(null, "")
      .required()
      .max(300)
      .label("To number"),
    description: Joi.string().max(1500).label("Description"),
    contentText: Joi.string().max(1500).label("SMS Content"),
  };

  const handleYes = async (data) => {
    data.actionFunction = "SMS";
    try {
      const response = await customActionService.addSMSTrigger(
        projects[activeProject]._id,
        data
      );

      if (response.status === 200) {
        setLoading(false);
        setReload(!reload);
        onCancel();
        message.success("Trigger added");
        // setTabState("Settings")
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
      setLoading(false);
    }
  };

  const getPreviewDataInParent = (dataDuplicate) => {
    if (!dataDuplicate) {
      setPreviewData("");
    }
    setPreviewData(dataDuplicate);
  };

  return (
      <Form
        key={JSON.stringify(inputField)}
        errorMessage={errorMessage}
        inputField={inputField}
        template={template}
        schema={schema}
        buttonInfo={addTriggerButtonInfo}
        onSubmit={handleYes}
        handleSecondary={onCancel}
        loading={loading}
        setLoading={setLoading}
        getValue={getPreviewDataInParent}
      />
  );
}

export default AddNewTrigger;
