import { useState } from "react";

import Form from "../../../components/Form";
import Joi from "joi-browser";
import { message } from "antd";

export function Call(props) {
  const { addUserToCampaign, onClose } = props;
  const [loading, setLoading] = useState(false);

  const [inputField] = useState({
    toNumber: "",
    fromNumber: "",
    triggerType: "",
    campaignType: "Call"
  });

  const [errorMessage] = useState({});

  const template = [
    {
      name: "toNumber",
      label: "To Number",
      description: "Please pre-fix country code to the number",
      type: "text",
    },

    {
      name: "fromNumber",
      label: "From Number",
      description: "Leave blank to use default number",
      type: "text",
    },

    {
      name: "triggerType",
      label: "Trigger type",
      type: "select",
      options: [
        {
          id: 0,
          value: "",
          label: "No selection",
        },
        {
          id: 1,
          value: "triggerImmediately",
          label: "Trigger Immediately",
        },
        {
          id: 2,
          value: "triggerBasedOnCampaign",
          label: "Trigger based on campaign configuration",
        },
      ],
    },
  ];

  const schema = {
    message: Joi.string().allow(null, "").max(160).label("message"),
    toNumber: Joi.string()
      .regex(/^\+?[0-9]+$/)
      .required()
      .label("To Number"),
    fromNumber: Joi.string()
      .regex(/^\+?[0-9]+$/)
      .allow(null, "")
      .label("From Number"),
    triggerType: Joi.string().required().label("Trigger Type"),
    campaignType: Joi.string().required().label("Campaign Type"),
  };

  return (
    <Form
      key={JSON.stringify(inputField)}
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={{
        style: "",
        buttons: "2",
        primaryLabel: "Add",
        secondaryLabel: "Close",
      }}
      onSubmit={(data) =>
        addUserToCampaign(
          data,
          (response) => {
            if (response.status === 200) {
              message.success("Success");
              onClose();
              setLoading(false);
            }
          },
          (error) => {
            message.error(
              error?.response?.data?.msg || "Failed to add contact"
            );
            onClose();
            setLoading(false);
            console.error(error);
          }
        )
      }
      loading={loading}
      setLoading={setLoading}
      handleSecondary={onClose}
    />
  );
}
