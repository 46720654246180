import React, { useState, useCallback, useEffect } from "react";

import * as customActionService from "../../../services/customActions";

import ExistingEndCallConditions from "./ExistingEndCallConditions";
import NewEndConditionDialog from "./NewEndCondition";
import EnableAutoCallEnd from "./EnableAutoCallEnd";

import CallEndIcon from "../../../images/endCall.svg";

function useCustomActions(projectId, actionFunction) {
  const [actions, setActions] = useState([]);

  const fetchActions = useCallback(async () => {
    try {
      const response = await customActionService.getAllCustomActions(projectId);
      const { customActions } = response.data;

      const actions = customActions.filter(
        (action) => action.actionFunction === actionFunction
      );
      setActions(actions);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  });

  return {
    actions,
    fetchActions,
  };
}

function ParentCallEnd({ projectId, settings, fetchSettings }) {
  const { actions, fetchActions } = useCustomActions(projectId, "CALLEND");

  useEffect(() => {
    if (projectId) {
      fetchActions();
    }
  }, [projectId]);

  return (
    <main className="w-full">
      <div className="flex justify-center p-8">
        <div className="w-full">
          <div className="flex flex-col gap-y-4">
            <div className="flex justify-between pb-8">
              <div className="flex gap-x-2 items-end">
                {/* <img src={CallEndIcon} alt="End call icon" width={25} /> */}
                <p className="font-semibold text-lg">End Call Conditions</p>
                <div className="self-end">
                  <EnableAutoCallEnd
                    projectId={projectId}
                    settings={settings}
                    fetchSettings={fetchSettings}
                  />
                </div>
              </div>
              <NewEndConditionDialog
                projectId={projectId}
                refetchActions={fetchActions}
              />
            </div>
            <ExistingEndCallConditions
              projectId={projectId}
              actions={actions}
              refetchActions={fetchActions}
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default ParentCallEnd;
