import React from "react";

import { Field, Label, ErrorMessage } from "../../../common/fieldset";
import { DatePicker } from "antd";

import dayjs from "dayjs";

export function StartAndEndDateField(props) {
  const { error, setError, setDate } = props;

  function onDateChange(date, dateString) {
    if (error.isError) {
      setError({
        isError: false,
        message: "",
      });
    }

    const [startDate, endDate] = date;

    if (startDate.isSame(endDate)) {
      const updatedStartDate = dayjs(startDate).set("minute", 1);
      const updatedEndDate = dayjs(endDate).set("hour", 23).set("minute", 59);
      setDate({
        startDate: updatedStartDate.format("x"),
        endDate: updatedEndDate.format("x"),
      });
      return;
    }
    const updatedStartDate = dayjs(startDate).set("minute", 1);
    const updatedEndDate = dayjs(endDate).set("hour", 23).set("minute", 59);

    setDate({
      startDate: updatedStartDate.format("x"),
      endDate: updatedEndDate.format("x"),
    });
  }

  return (
    <Field>
      <Label>Choose Start & End date</Label>
      <DatePicker.RangePicker
        size="medium"
        className="border-slate-200 font-medium text-xl w-full mt-4"
        maxDate={dayjs()}
        variant="outlined"
        placeholder={["Start date", "End date"]}
        onChange={onDateChange}
        status={error.isError && "error"}
      />
      {error.isError && (
        <ErrorMessage className="pt-2">{error.message}</ErrorMessage>
      )}
    </Field>
  );
}
